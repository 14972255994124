//  <!-- 모바일 뒤로가기 , 확인 아이콘 JAVASCRIPT -->
const UserID = document.querySelector(".userID");




//// [ 모바일 뒤로가기 ]
function mobileBackJS(){
    const MobileBack = document.querySelector('.mobileBack');
    const Hash = window.location.hash;
    const validHashes = ['#edit-profile', '#change-password', '#withdrawal', '#videoupload', '#inquiryupload', '#videoEdit' ];
    if (window.innerWidth > 1000) {
        MobileBack.style = 'display:none';
    } else if (validHashes.some(validHash => Hash.includes(validHash))) {
        MobileBack.style = 'display:flex;';
    } else {
        MobileBack.style = 'display:none';
    }
}
if(UserID){
    window.addEventListener('hashchange', mobileBackJS);
    window.addEventListener('load', mobileBackJS);
    window.addEventListener('resize', mobileBackJS);
    document.getElementById('mobileBackIcon').addEventListener('click', function() {
      window.history.back();
    });
}




//// [ 모바일 확인 아이콘 클릭 시 ]
function confirmClick(){
    const Confirm = document.getElementById('confirm');
    Confirm.innerHTML = '<i class="bi bi-check-lg"></i>';
    const EditProfileSubmitBtn = document.querySelector('.editProfile_submit');
    const ChangePasswordSubmitBtn = document.querySelector('.changePassword_submit'); 
    const WithdrawalSubmitBtn = document.querySelector('.withdrawal_submit'); 
    const InquiryUploadSubmitBtn = document.querySelector('.inquiryUpload_submit');
    const VideoEditSubmit = document.querySelector('.videoEdit_submit'); 
    const VideoUploadSubmit = document.querySelector(".videoUpload_submit");
    Confirm.addEventListener('click', function() {
      const Hash = window.location.hash;
      if(Hash.includes('#edit-profile')){
        EditProfileSubmitBtn.click();
      }else if(Hash.includes('#change-password')){
        ChangePasswordSubmitBtn.click();
      }else if(Hash.includes('#withdrawal')){
        WithdrawalSubmitBtn.click();
      }else if(Hash.includes('#inquiryupload')){
        InquiryUploadSubmitBtn.click();
      }else if(Hash.includes('#videoEdit')){
        VideoEditSubmit.click();
      }else if(Hash.includes('#videoupload')){
        VideoUploadSubmit.click();
      }
    });
}
if(UserID){
  confirmClick();
}