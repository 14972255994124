const UserID = document.querySelector(".userID");

function searchVideoJS() {
    const Hash = window.location.hash;
    const SearchVideoBlockBg = document.querySelector(".sVvideoBlock-bg");
    const SearchVideoBlock = document.querySelector(".sVvideoBlock");
    if (Hash.includes('#search')) {
        SearchVideoBlockBg.style.display ="block";
        SearchVideoBlock.innerHTML = "";
        const SearchValue = Hash.split('/')[1];
        const decodedValue = decodeURIComponent(SearchValue); // URL 디코딩
        const Value = decodedValue.replace(/\s+/g, ''); // 띄어쓰기를 제거
        
        fetch('/api/searchVideo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ searchTerm: Value })
        })
        .then(response => response.json())
        .then(data => {
            if(data.video){
                data.video.forEach(item => {
                    const Div = document.createElement('div');
                    Div.innerHTML = `
                        <div class="sV-Block" data-videoUrl="${item.hlsVideoUrl}" data-id="${item._id}">
                            <a href="#videodetail/sV/poniVideo${item._id}/?${item.hlsVideoUrl}">
                                <div class="thumbnail-bg">
                                    ${item.thumbnailUrl === "thumbnail-None" ? 
                                        `<video class="thumbnail thumbnailVideos" loop controlsList="nodownload"></video>` :
                                        `<img class="thumbnail thumbnailImg" src="${item.thumbnailUrl}" alt="Img"/>
                                         <video class="thumbnail thumbnailVideo" loop controlsList="nodownload"></video>`
                                    }
                                    <div class="videoPlayer">
                                        <div class="videoPlayPause video-play-pause">
                                            <i class="fas fa-pause"></i>
                                        </div>
                                        <div class="video-controls">
                                            <div class="progress-bar">
                                                <div class="progress-filled" style="width: 0%;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="block-hidden hidden">
                                <span class="hlsVideoUrl">${item.hlsVideoUrl}</span>
                                <span class="thumbnailUrl">${item.thumbnailUrl}</span>
                                <span class="block-videoId" data-id="${item._id}">${item._id}</span>
                            </div>
                            <div class="videoInfo">
                                <span class="videoTitle">${item.title}</span>
                                <div class="videoCreator">
                                    <a href="">
                                        <img class="videoCreator-avater" src="${item.creator.avatarUrl}" alt="Creator Avatar">
                                        <span class="videoCreator-name">${item.creator.name}</span>
                                    </a>
                                    <div class="videoSetting">
                                        <i class="bi bi-three-dots-vertical"></i>
                                    </div>
                                    <div class="videoSettingModalBg">
                                        <div class="videoSettingModal">
                                            ${item.creatorValue === "me" ? 
                                                `<a class="settingEditVideo" href="#/videoEdit/${item._id}">비디오 수정</a>` : ''}
                                            <a class="settingReport" href="#">신고</a>
                                        </div>
                                    </div>
                                </div>
                                ${item.creatorValue !== "me" ? 
                                    `<div class="video-saveBtnBg">
                                        <button class="video-saveBtn"></button>
                                        <span class="saveValue hidden">${item.saveValue}</span>
                                    </div>` : ''}
                            </div>
                            <button class="block-playBtn hidden">재생하기</button>
                        </div>
                    `;
                    if(SearchVideoBlock){
                        SearchVideoBlock.appendChild(Div);

                        const SvVideoBlocks = document.querySelectorAll('.sV-Block');
                        const VideoUrlBase = 'https://video.ponigress.com/';
                    
                        SvVideoBlocks.forEach((Block) => {
                          const VideoElement = Block.querySelector('video');
                          const HlsVideoUrl = Block.querySelector('.hlsVideoUrl').textContent;
                          const VideoUrl = `${VideoUrlBase}${HlsVideoUrl}`;
                          const BlockPlayBtn = Block.querySelector('.block-playBtn');
                    
                          BlockPlayBtn.addEventListener('click', function () {
                            Block.className = Block.className.replace('opacityZero', '');
                          });
                    
                          if (Hls.isSupported()) {
                            const hls = new Hls();
                            hls.loadSource(VideoUrl);
                            hls.attachMedia(VideoElement);
                            hls.on(Hls.Events.MANIFEST_PARSED, function () {
                              hls.currentLevel = 1;
                            });
                    
                            const handleCanPlay = () => {
                              VideoElement.muted = true; // 소리 끄기
                              VideoElement.currentTime = 0.5;
                              VideoElement.removeEventListener('canplaythrough', handleCanPlay);
                              setTimeout(() => {
                                BlockPlayBtn.click();
                              }, 1000);
                            };
                            VideoElement.addEventListener('canplaythrough', handleCanPlay);
                          } else if (VideoElement.canPlayType('application/vnd.apple.mpegurl')) {
                            VideoElement.src = VideoUrl;
                          }
                        });
                    }
                });
            }
        })
    }else{
        SearchVideoBlockBg.style.display ="none";
        SearchVideoBlock.innerHTML = "";
    }
}
if (UserID) {
    window.addEventListener('load', searchVideoJS);
    window.addEventListener('hashchange', searchVideoJS);
}