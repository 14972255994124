//  <!-- 공통부분 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 전체 스크롤 해시 / 로드 ]
const BodyContent = document.querySelector('.bodyContent');
function hideScrollbar() {
  document.body.style.overflow = 'hidden';
}
function showScrollbar() {
  document.body.style.overflow = 'auto';
}
function scrollJS(){
  if(BodyContent){
    BodyContent.scrollTop = 0;
  }
  hideScrollbar();
  document.body.scrollIntoView();
  showScrollbar();
}
window.addEventListener('hashchange', scrollJS);
window.addEventListener('load', scrollJS);



//// [ 모바일 확인 아이콘 클릭 시 ]
function confirmClick(){
  const Confirm = document.getElementById('confirm');
  Confirm.innerHTML = '<i class="bi bi-check-lg"></i>';
  const EditProfileSubmitBtn = document.querySelector('.editProfile_submit');
  const ChangePasswordSubmitBtn = document.querySelector('.changePassword_submit'); 
  const WithdrawalSubmitBtn = document.querySelector('.withdrawal_submit'); 
  const InquiryUploadSubmitBtn = document.querySelector('.inquiryUpload_submit');
  const VideoEditSubmit = document.querySelector('.videoEdit_submit'); 
  Confirm.addEventListener('click', function() {
    const Hash = window.location.hash;
    if(Hash.includes('#edit-profile')){
      EditProfileSubmitBtn.click();
    }else if(Hash.includes('#change-password')){
      ChangePasswordSubmitBtn.click();
    }else if(Hash.includes('#withdrawal')){
      WithdrawalSubmitBtn.click();
    }else if(Hash.includes('#inquiryupload')){
      InquiryUploadSubmitBtn.click();
    }else if(Hash.includes('#videoEdit')){
      VideoEditSubmit.click();
    }
  });
}
if(UserID){
  confirmClick();
}