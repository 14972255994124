// <!-- Block 순서 재정렬 JAVASCRIPT -->
// 참고: Block 순서가 자꾸 변경되는 에러가 발생되서 다시 원래 자리로 Block을 위치 시키는 작업
const UserID = document.querySelector(".userID");

//// [ uS-Block 순서 텍스트 재배치 ]
function uSblockReset() {
    const UsVideoBlock = document.querySelector('.uSvideoBlock');
    if (!UsVideoBlock) return;

    const UsBlocks = Array.from(UsVideoBlock.querySelectorAll('.uS-Block'));

    // 요소를 정렬합니다.
    UsBlocks.sort((a, b) => {
        const aIndex = parseInt(a.querySelector('.block-number').textContent, 10);
        const bIndex = parseInt(b.querySelector('.block-number').textContent, 10);
        return aIndex - bIndex;
    });

    // 정렬된 요소를 한 번에 재배치하고 번호를 업데이트합니다.
    UsBlocks.forEach((block, index) => {
        UsVideoBlock.appendChild(block); // 부모 컨테이너에 추가
        const BlockNumber = block.querySelector('.block-number');
        if (BlockNumber) {
            BlockNumber.textContent = index; // 순서 업데이트
        }
    });
}


//// [ uV-Block 순서 텍스트 재배치 ]
function uVblockReset() {
    const UvVideoBlock = document.querySelector('.uVvideoBlock');
    if (!UvVideoBlock) return;

    const UvBlocks = Array.from(UvVideoBlock.querySelectorAll('.uV-Block'));

    // 요소를 정렬합니다.
    UvBlocks.sort((a, b) => {
        const aIndex = parseInt(a.querySelector('.block-number').textContent, 10);
        const bIndex = parseInt(b.querySelector('.block-number').textContent, 10);
        return aIndex - bIndex;
    });

    // 정렬된 요소를 한 번에 재배치하고 번호를 업데이트합니다.
    UvBlocks.forEach((block, index) => {
        UvVideoBlock.appendChild(block); // 부모 컨테이너에 추가
        const BlockNumber = block.querySelector('.block-number');
        if (BlockNumber) {
            BlockNumber.textContent = index; // 순서 업데이트
        }
    });
}
if(UserID){
    window.addEventListener('hashchange', () => {
        const Hash = window.location.hash;
        if(Hash.includes('#users')){
            // 혹시라도 제자리로 안갈때 setTimeout 하기.
            uSblockReset();
            uVblockReset();
        }
    });
}
// 참고: 저장 시 순서 텍스트를 변경해줘도 해시 변경시 한칸 씩 밀려나는 에러가 발생하였다. 일단 위에 코드로 임시로 해결은 했지만 순서가 안맞을수도 있다.