// <!-- 유저디테일 JAVASCRIPT -->
const UserID = document.querySelector(".userID");




//// [ 유저디테일 보이기/숨기기 ]
function userDetailJS() {
  const Hash = window.location.hash;
  const UserDetailBg = document.querySelector(".userDetail-bg");
  if (Hash.includes('#users')) {
    UserDetailBg.style ="width:100%; height:auto; visibility:visible; overflow:visible;";
  }else{
    UserDetailBg.style ="width:0; height:0; visibility:hidden; overflow:hidden; padding-top:0px;";
  }
}
if (UserID) {
  window.addEventListener("load", userDetailJS);
  window.addEventListener("hashchange", userDetailJS);
}



//// 아이콘 버튼 클릭 시 
function userDetailIcon() {
  const UserSaveBtnIcon = document.querySelector(".userInfo_userSaveBtn i");
  const UserVideoBtnIcon = document.querySelector(".userInfo_userVideoBtn i");
  const BookmarkBottom = document.querySelector(".bookmark_bottom");
  const GridBottom = document.querySelector(".grid_bottom");
  const UvvideoBlockBg = document.querySelector(".uVvideoBlock-bg");
  const UsvideoBlockBg = document.querySelector(".uSvideoBlock-bg");
  UvvideoBlockBg.classList.add("hiddens");
  UserSaveBtnIcon.addEventListener('click', function() {
    UserSaveBtnIcon.style="color:black;";
    UserVideoBtnIcon.style="color:rgb(114, 114, 114);";
    BookmarkBottom.style ="background-color:black;";
    GridBottom.style ="background-color:white;";
    UsvideoBlockBg.classList.remove("hiddens");
    UvvideoBlockBg.classList.add("hiddens");
  });
  UserVideoBtnIcon.addEventListener('click', function() {
    UserSaveBtnIcon.style="color:rgb(114, 114, 114);";
    UserVideoBtnIcon.style="color:black;";
    BookmarkBottom.style ="background-color:white;";
    GridBottom.style ="background-color:black;";
    UsvideoBlockBg.classList.add("hiddens");
    UvvideoBlockBg.classList.remove("hiddens");
  });
}
if (UserID) {
  window.addEventListener('load', userDetailIcon);
}