// <!-- 이용약관 로그인 모달창 JAVASCRIPT -->
const TermsModalText = document.querySelector(".terms_modalText");

//// [ 이용약관 로그인 모달창 언어번역, 보이기/숨기기 ]
function termsModalTextJS(){
    if(window.location.hash === "#signup"){
        TermsModalText.style.display="flex";
        
        const TermsModalTEXT = {
            en: { // 영어
                TermsHead: "Terms",
                
                TermsTitleA: "Article 1 (Purpose)",
                TermsA1: "The purpose of this agreement is to stipulate the rights, obligations, and responsibilities of the cyber mall ('Mall') and its users in using the internet-related services ('Services') provided by 'TalkJam' company (hereinafter referred to as the 'Company'), an e-commerce business operator, operating the 'TalkJam' cyber mall.",
                TermsA2: "※ 'These terms shall apply to electronic commerce using PC communication and wireless communication, unless it violates its nature.'",
                
                TermsTitleB: "Article 2 (Definitions)",
                TermsB1: "1. 'Mall' refers to a virtual business place set up by 'TalkJam' company to enable users to trade goods or services ('Goods, etc.') using information and communication facilities such as computers, and also refers to the meaning of a business operator operating a cyber mall.",
                TermsB2: "2. 'User' refers to a person who accesses the 'Mall' and agrees to the terms and uses the 'Mall' services provided by the 'Mall'.",
                TermsB3: "3. 'Member' refers to a person who has registered as a member by providing personal information to the 'Mall' and who can continue to use the services provided by the 'Mall' continuously.",
                TermsB4: "4. 'Non-member' refers to a person who uses the services provided by the 'Mall' without registering as a member.",
                
                
                TermsTitleC: "Article 3 (Notice and Explanation of terms, and Amendment)",
                TermsC1: "1. The 'Mall' shall post the contents of these terms, its name and representative's name, business location address (including the address where consumer complaints can be handled), telephone number, facsimile number, email address, business registration number, mail-order sales business registration number, and person responsible for personal information management, on the initial service screen (front) of 'TalkJam' cyber mall to allow users to easily access them. However, the contents of the terms can be made available through a connection screen.",
                TermsC2: "2. 'Before the user agrees to the terms, the 'Mall' must provide a separate connection screen or popup screen to allow the user to confirm important contents such as withdrawal/refund conditions specified in the terms.",
                TermsC3: "3. 'Within the scope not violating relevant laws such as the Electronic Commerce Consumer Protection Act, Act on Regulation of the terms, Electronic Documents and Transactions Act, Electronic Financial Transactions Act, Electronic Signature Act, Act on Promotion of Information and Communications Network Utilization and Information Protection, Etc., Door-to-Door Sales Act, and Consumer Basic Act, the 'Mall' may revise these terms.",
                TermsC4: "4. When the 'Mall' revises the terms, it shall post the revised terms together with the current terms on the initial screen of the mall from 7 days before the effective date of the revision to the day before the effective date of the revision. However, if the 'Mall' changes the contents of the terms unfavorably to the user, it shall give notice with a grace period of at least 30 days in advance. In this case, the 'Mall' shall clearly indicate the contents before and after the revision to make it easy for users to understand.",
                TermsC5: "5. When the 'Mall' revises the terms, the revised terms shall apply only to contracts concluded after the effective date, and the previous terms shall continue to apply to contracts already concluded. However, if a user who has already entered into a contract expresses his/her intention to apply the revised terms within the notice period of the revised terms pursuant to Paragraph 3, the revised terms shall apply.",
                TermsC6: "6. Matters not specified in these terms and the interpretation of these terms shall be governed by the Electronic Commerce Consumer Protection Act, Act on Regulation of the terms, guidelines for consumer protection in electronic commerce established by the Fair Trade Commission, relevant laws and regulations, or commercial practices.",
                


                TermsTitleD: "Article 4 (Provision and Amendment of Services)",
                TermsD1: "1. The 'Mall' shall perform the following tasks:",
                TermsD1_1: "1. Providing information on goods or services and entering into purchase contracts",
                TermsD1_2: "2. Other tasks designated by the 'Mall'",
                TermsD2: "2. In case of the sale of goods or services becoming out of stock or changes in technical specifications, etc., the 'Mall' may change the contents of the goods or services to be provided under the future contracts. In this case, the 'Mall' shall immediately announce the changed contents of the goods or services and the date of provision in the place where the current contents of the goods or services are posted.",
                TermsD3: "3. If the 'Mall' needs to change the content of the service contracted with the user due to the goods being out of stock or changes in technical specifications, etc., the 'Mall' shall notify the user immediately to an address where notification is possible.",
                TermsD4: "4. In the case of the preceding paragraph, the 'Mall' shall compensate for any damages incurred by the user. However, this shall not apply if the 'Mall' proves that there is no intentional or negligent act on its part.",
                
                
                TermsTitleE: "Article 5 (Suspension of Services)",
                TermsE1: "1. The 'Mall' may temporarily suspend the provision of services when there are reasons such as maintenance, replacement, or breakdown of information and communication facilities such as computers, or interruption of communication.",
                TermsE2: "2. If the provision of services is temporarily suspended for the reasons specified in Paragraph 1, the 'Mall' shall compensate the user or a third party for any damages incurred. However, this shall not apply if the 'Mall' proves that there is no intentional or negligent act on its part.",
                TermsE3: "3. In the event that the provision of services becomes impossible due to reasons such as a change in the business category, abandonment of the business, or integration of businesses, the 'Mall' shall notify the user through the method specified in Article 8 and compensate the consumer according to the conditions originally presented by the 'Mall'. However, if the 'Mall' does not notify the standards for compensation, the 'Mall' shall pay the user in cash or goods equivalent to the value of the mileage or accumulated funds applicable to the 'Mall' at the time of payment.",
                
                
                TermsTitleF: "Article 6 (Membership Registration)",
                TermsF1: "1. Users apply for membership registration by filling out the membership information according to the form specified by the 'Mall' and expressing their consent to these terms.",
                TermsF2: "2. The 'Mall' shall register the user as a member, except in cases where the user falls under any of the following subparagraphs when applying for membership as stipulated in Paragraph 1:",
                TermsF2_1: "1. If there is a prior case where the membership qualification has been lost in accordance with Paragraph 3 of Article 7 of these terms, except in cases where 3 years have elapsed since the loss of membership qualification under Paragraph 3 of Article 7 and the user has obtained approval for re-registration from the 'Mall'.",
                TermsF2_2: "2. If there is false, missing, or incorrect information in the registration details",
                TermsF2_3: "3. If it is determined that registering the user as a member would significantly impede the technical operation of the 'Mall'",
                TermsF3: "3. The time of conclusion of the membership registration contract shall be when the 'Mall' acceptance reaches the user.",
                TermsF4: "4. If there is any change in the details provided during membership registration, the member must notify the 'Mall' of the changes promptly through the method of modifying the member information, etc., provided by the 'Mall'.",
                
                
                TermsTitleG: "Article 7 (Withdrawal of Membership and Loss of Qualification)",
                TermsG1: "1. Members may request withdrawal of membership from the 'Mall' at any time, and the 'Mall' shall process the withdrawal of membership immediately.",
                TermsG2: "2. If a member falls under any of the following subparagraphs, the 'Mall' may restrict or suspend the membership qualification:",
                TermsG2_1: "1. If false information is provided at the time of application for membership",
                TermsG2_2: "2. If the member fails to pay the price of the goods, etc., purchased through the 'Mall' or other obligations owed to the 'Mall' on the due date",
                TermsG2_3: "3. If the member interferes with another person's use of the 'Mall' or steals their information, thereby threatening the order of electronic commerce",
                TermsG2_4: "4. If the member engages in acts prohibited by law or these terms through the use of the 'Mall'",
                TermsG3: "3. If the 'Mall' restricts or suspends the membership qualification and the same act is repeated more than twice or the reason is not corrected within 30 days, the 'Mall' may terminate the membership qualification.",
                TermsG4: "4. If the 'Mall' terminates the membership qualification, it shall cancel the member registration. In this case, the 'Mall' shall notify the member of this and provide an opportunity to explain for a minimum of 30 days before canceling the member registration.",
                
                
                TermsTitleH: "Article 8 (Notice to Members)",
                TermsH1: "1. When giving notice to members, the 'Mall' may use the email address agreed upon in advance between the 'Mall' and the member.",
                TermsH2: "2. When giving notice to an unspecified number of members, the 'Mall' may substitute individual notice by posting on the 'Mall' bulletin board for more than 1 week. However, individual notice shall be given for matters significantly affecting the member's transactions.",
                
                
                TermsTitleI: "Article 9 (Application for Purchase and Consent to Personal Information Provision, Etc.)",
                TermsI1: "1. Users of the 'Mall' shall apply for purchase through the 'Mall' using the following or similar methods, and the 'Mall' shall provide the following details to enable users to easily understand the purchase request.",
                TermsI1_1: "1. Selection of goods, etc.",
                TermsI1_2: "2. Selection of payment method",
                TermsI2: "2. The validity period of the service upon purchase shall be determined according to the period selected at the time of purchase.",
                TermsI3: "3. If the 'Mall' needs to provide the purchaser's personal information to a third party, the 'Mall' must inform the purchaser of 1) the recipient of the personal information, 2) the purpose of using the personal information by the recipient, 3) the items of personal information to be provided, and 4) the period of retention and use of the personal information by the recipient, and obtain consent from the purchaser. (This also applies if the agreed matters change.)",
                TermsI4: "4. If the 'Mall' entrusts the handling of the purchaser's personal information to a third party to perform its duties, it must inform the purchaser of 1) the recipient of the personal information entrusted, 2) the contents of the entrusted personal information handling, and obtain consent from the purchaser. (This also applies if the agreed matters change.) However, if it is necessary to fulfill the service contract and is related to the convenience of the purchaser, the 'Mall' may inform the purchaser through the personal information handling policy without going through the notification and consent procedures, as provided by the Act on Promotion of Information and Communications Network Utilization and Information Protection, Etc.",
                
                
                TermsTitleJ: "Article 10 (Formation of Contract)",
                TermsJ1: "1. The 'Mall' may not accept a purchase request under the following subparagraphs when it comes to the purchase request as stipulated in Article 9:",
                TermsJ1_1: "1. If there is false, missing, or incorrect information in the application details",
                TermsJ1_2: "2. If a minor purchases goods or services prohibited by the Juvenile Protection Act such as tobacco, alcohol, etc., through the 'Mall'",
                TermsJ1_3: "3. If it is determined that accepting the purchase request would significantly impede the technical operation of the 'Mall'",
                TermsJ2: "2. The contract shall be deemed concluded at the time when the acceptance of the 'Mall' reaches the user in the form of a confirmation of receipt notification pursuant to Paragraph 1 of Article 12.",
                TermsJ3: "3. The acceptance of the 'Mall' must include confirmation of the purchase request, sale availability, and information regarding correction or cancellation of the purchase request.",
            


                TermsTitleK:"Article 11 (Payment Method)",
                TermsK1:"The payment methods for goods or services purchased from the 'mall' may be made by any of the following methods. However, the 'mall' cannot charge any additional fees under any pretext for the payment method of the user.",
                TermsK1_1:"1. Various account transfers such as phone banking, internet banking, and mail banking",
                TermsK1_2:"2. Various card payments such as prepaid cards, debit cards, and credit cards",
                TermsK1_3:"3. Online non-face-to-face deposit",
                TermsK1_4:"4. Payment by electronic currency",
                TermsK1_5:"5. Payment by gift certificates contracted with the 'mall' or recognized by the 'mall'",
                TermsK1_6:"6. Payment by other electronic payment methods",
                
                
                TermsTitleL:"Article 12 (Receipt Confirmation Notice, Change, and Cancellation of Purchase Request)",
                TermsL1:"① The 'mall' shall send a receipt confirmation notice to the user when there is a purchase request from the user.",
                TermsL2:"② If there is any discrepancy in the intention of the user after receiving the receipt confirmation notice, the user may request a change or cancellation of the purchase request immediately after receiving the receipt confirmation notice, and it shall be subject to the provisions of Article 15 regarding withdrawal of subscription, etc.",
                
                
                TermsTitleM:"Article 13 (Supply of Goods, etc.)",
                TermsM1:"The 'mall' shall supply goods or services from the time the user purchases them.",
                
                
                TermsTitleN:"Article 14 (Refund)",
                TermsN1:"When the 'mall' cannot provide the goods purchased by the user, it shall promptly notify the reason to the user and refund the payment within 3 business days from the date of receipt of the payment for the goods or take necessary measures for the refund.",
                
                
                TermsTitleO:"Article 15 (Subscription Withdrawal, etc.)",
                TermsO1:"① Users who have entered into a contract with the 'mall' regarding the purchase of goods may withdraw their subscription within 7 days from the date of receiving a written document regarding the contract pursuant to Article 13(2) of the Act on Consumer Protection in Electronic Commerce, etc. However, withdrawal of subscription due to a simple change of mind shall be limited, and if there are other provisions regarding withdrawal of subscription in the Act on Consumer Protection in Electronic Commerce, etc., those provisions shall apply.",
                TermsO2:"② If the content of the goods or services is different from the display or advertisement content, or if it is not performed as agreed upon, the user may withdraw the subscription, etc. within 3 days from the date of receiving the goods or within 30 days from the date when the user knew or could have known about the fact.",
                
                
                TermsTitleP:"Article 16 (Effects of Subscription Withdrawal, etc.)",
                TermsP1:"When refunding the above amount, the 'mall' shall immediately request the business operator who provided the payment method such as credit card or electronic currency to stop or cancel the claim for the payment of the price of the goods.",
                
                
                TermsTitleQ:"Article 17 (Protection of Personal Information)",
                TermsQ1:"① When collecting personal information of users, the 'mall' shall collect the minimum necessary personal information within the scope necessary for providing services.",
                TermsQ2:"② The 'mall' does not collect the information necessary for performing purchase contracts in advance at the time of membership registration. However, if it is necessary to confirm the identity before entering into a purchase contract pursuant to relevant laws and regulations, it does not apply.",
                TermsQ3:"③ When collecting and using user's personal information, the 'mall' shall notify the user of the purpose and obtain consent.",
                TermsQ4:"④ The 'mall' may not use the collected personal information for purposes other than the intended purpose, and if there is a new purpose for use or if it is provided to a third party, the 'mall' shall notify the user of the purpose and obtain consent at the stage of use or provision. However, this shall not apply if there are different provisions in related laws and regulations.",
                TermsQ5:"⑤ When the user's consent is required pursuant to paragraphs (2) and (3), the 'mall' shall specify or notify in advance the matters specified in Article 22(2) of the Act on Promotion of Information and Communication Network Utilization and Information Protection, etc., such as the identity of the personal information manager (affiliation, name, and telephone number, and other contact information), purpose of collection and purpose of use of information, and matters related to provision of information to third parties (recipient, purpose of provision, and content of information to be provided), and the user may withdraw the consent at any time.",
                TermsQ6:"⑥ The user may request to view and correct errors in his/her personal information held by the 'mall' at any time, and the 'mall' shall promptly take necessary measures. If the user requests correction of errors, the 'mall' shall not use the personal information until the error is corrected.",
                TermsQ7:"⑦ The 'mall' shall limit the minimum number of persons handling personal information of users to protect personal information of users and shall be responsible for all damages to users caused by loss, theft, leakage, provision to third parties without consent, alteration, etc., including credit card information and bank account information.",
                TermsQ8:"⑧ The 'mall' or third party receiving personal information from it shall promptly destroy the personal information when the purpose of collection or provision is achieved.",
                TermsQ9:"⑨ The 'mall' does not pre-set the consent box for collection, use, and provision of personal information. In addition, when the user refuses consent to the collection, use, and provision of personal information not essential for membership registration, the 'mall' shall specify the services restricted by refusal of consent to the collection, use, and provision of personal information not essential for membership registration and shall not restrict or refuse to provide services such as membership registration based on refusal of consent to the collection, use, and provision of personal information not essential for membership registration.",


                TermsTitleR: "Article 18 (Obligations of the 'Mall')",
                TermsR1: "① The 'Mall' shall not engage in activities prohibited by law or contrary to public order and morals, and must do its best to provide goods and services continuously and stably in accordance with these terms",
                TermsR2: "② The 'Mall' must have a security system to protect users' personal information (including credit information) so that users can use internet services safely.",
                TermsR3: "③ If the 'Mall' causes users damage by unfair display or advertising actions regarding goods or services under the 'Act on Fair Labeling and Advertising', it is responsible for compensating for the damage.",
                TermsR4: "④ The 'Mall' shall not send commercial emails for profit-making purposes that users do not want.",
                
                
                TermsTitleS: "Article 19 (Duties on Member's ID and Password)",
                TermsS1: "① Except in the case of Article 17, members are responsible for the management of their ID and password.",
                TermsS2: "② Members should not allow their ID and password to be used by a third party.",
                TermsS3: "③ If a member recognizes that their ID and password have been stolen or are being used by a third party, they must immediately notify the 'Mall', and follow the guidance of the 'Mall', if provided.",
                
                
                TermsTitleT: "Article 20 (User's Duties)",
                TermsT1: "Users shall not engage in the following acts:",
                TermsT1_1: "1. Registering false information at the time of application or modification",
                TermsT1_2: "2. Stealing information from others",
                TermsT1_3: "3. Changing information posted on the 'Mall'",
                TermsT1_4: "4. Transmitting or posting information (such as computer programs) other than the information designated by the 'Mall'",
                TermsT1_5: "5. Infringing upon the intellectual property rights of the 'Mall' or a third party",
                TermsT1_6: "6. Damaging the reputation of the 'Mall' or interfering with its work",
                TermsT1_7: "7. Posting or disclosing obscene or violent messages, images, voices, and other information contrary to public order and morals on the mall",
                
                
                TermsTitleU: "Article 21 (Ownership and Limitations on Use of Copyrights)",
                TermsU1: "① Copyright and other intellectual property rights in works created by the 'Mall' are owned by the 'Mall'.",
                TermsU2: "② Users should not use the information obtained by using the 'Mall', which is copyrighted by the 'Mall', for commercial purposes or allow third parties to use it without the prior consent of the 'Mall'.",
                TermsU3: "③ The 'Mall' must notify the user if it uses the copyright that belongs to the user according to the agreement.",
                
                
                TermsTitleV: "Article 22 (Dispute Resolution)",
                TermsV1: "① The 'Mall' will establish and operate a compensation processing organization to reflect legitimate opinions or complaints raised by users and to compensate for damages.",
                TermsV2: "② The 'Mall' will prioritize handling complaints and opinions submitted by users. If rapid processing is difficult, the reason and the schedule for processing will be immediately notified to the user.",
                TermsV3: "③ In case of an e-commerce dispute between the 'Mall' and a user that involves a user's application for damage relief, it can be subject to mediation by the Fair Trade Commission or a dispute mediation body commissioned by the city or provincial governor.",
                
                
                TermsTitleW: "Article 23 (Jurisdiction and Governing Law)",
                TermsW1: "① Litigation related to e-commerce disputes between the 'Mall' and a user will be governed by the exclusive jurisdiction of the district court that oversees the user's address at the time of filing. If the address is not clear at the time of the lawsuit or if the user is a foreign resident, it will be filed in accordance with the jurisdiction of the Civil Procedure Act.",
                TermsW2: "② Korean law will be applied to litigation between the 'Mall' and a user.",
                
                
                TermsTitleX: "These terms will be effective from January 1, 2024."
            },
            ko: { // 한국어
                TermsHead:"이용약관",

                TermsTitleA:"제 1조(목적)",
                TermsA1:"이 약관은 '포니그레스' 회사(전자상거래 사업자)가 운영하는 '포니그레스' 사이버 몰(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.",
                TermsA2:"※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」",

                TermsTitleB:"제 2조(정의)",
                TermsB1:"① “몰”이란 '포니그레스' 회사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.",
                TermsB2:"② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공",
                TermsB3:"③ ‘회원’이라 함은 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.",
                TermsB4:"④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.",

                TermsTitleC:"제 3조 (약관 등의 명시와 설명 및 개정)",
                TermsC1:"① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 '포니그레스' 사이버몰의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.",
                TermsC2:"② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.",
                TermsC3:"③ “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.",
                TermsC4:"④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 '몰'은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.",
                TermsC5:"⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.",
                TermsC6:"⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.",


                TermsTitleD:"제 4조(서비스의 제공 및 변경)",
                TermsD1:"① “몰”은 다음과 같은 업무를 수행합니다.",
                TermsD1_1:"1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결",
                TermsD1_2:"2. 기타 “몰”이 정하는 업무",
                TermsD2:"② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.",
                TermsD3:"③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.",
                TermsD4:"④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.",


                TermsTitleE:"제 5조(서비스의 중단)",
                TermsE1:"① “몰”은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.",
                TermsE2:"② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.",
                TermsE3:"③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.",
                
                
                
                TermsTitleF:"제 6조(회원가입)",
                TermsF1:"① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.",
                TermsF2:"② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.",
                TermsF2_1:"1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.",
                TermsF2_2:"2. 등록 내용에 허위, 기재누락, 오기가 있는 경우",
                TermsF2_3:"3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고 판단되는 경우",
                TermsF3:"③ 회원가입계약의 성립 시기는 “몰”의 승낙이 회원에게 도달한 시점으로 합니다.",
                TermsF4:"④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “몰”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.",
                
                
                
                TermsTitleG:"제 7조(회원 탈퇴 및 자격 상실 등)",
                TermsG1:"① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시 회원탈퇴를 처리합니다.",
                TermsG2:"② 회원이 다음 각 호의 사유에 해당하는 경우, “몰”은 회원자격을 제한 및 정지시킬 수 있습니다.",
                TermsG2_1:"1. 가입 신청 시에 허위 내용을 등록한 경우",
                TermsG2_2:"2. “몰”을 이용하여 구입한 재화 등의 대금, 기타 “몰”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우",
                TermsG2_3:"3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우",
                TermsG2_4:"4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우",
                TermsG3:"③ “몰”이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.",
                TermsG4:"④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.",
                
                
                
                TermsTitleH:"제 8조(회원에 대한 통지)",
                TermsH1:"① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.",
                TermsH2:"② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “몰” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.",


                TermsTitleI:"제 9조(구매신청 및 개인정보 제공 동의 등) ",
                TermsI1:"① “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. ",
                TermsI1_1:"1. 재화 등의 선택",
                TermsI1_2:"2. 결제방법의 선택",
                TermsI2:"② 구매 시 서비스의 유효기간은 구매 시 선택한 기간에 따릅니다.",
                TermsI3:"③ “몰”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)",
                TermsI4:"④ “몰”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.",


                TermsTitleJ:"제 10조(계약의 성립)",
                TermsJ1:"① “몰”은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.",
                TermsJ1_1:"1. 신청 내용에 허위, 기재누락, 오기가 있는 경우",
                TermsJ1_2:"2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우",
                TermsJ1_3:"3. 기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고 판단하는 경우",
                TermsJ2:"② “몰”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.",
                TermsJ3:"③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.",



                TermsTitleK:"제 11조(지급방법)",
                TermsK1:"몰”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “몰”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.",
                TermsK1_1:"1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체",
                TermsK1_2:"2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제",
                TermsK1_3:"3. 온라인무통장입금",
                TermsK1_4:"4. 전자화폐에 의한 결제",
                TermsK1_5:"5. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제",
                TermsK1_6:"6. 기타 전자적 지급 방법에 의한 대금 지급 등",


                TermsTitleL:"제 12조(수신확인통지․구매신청 변경 및 취소)",
                TermsL1:"① “몰”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.",
                TermsL2:"② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있으며 제15조의 청약철회 등에 관한 규정에 따릅니다.",


                TermsTitleM:"제 13조(재화 등의 공급)",
                TermsM1:"“몰”은 이용자가 구매한 시점으로부터 재화 등을 공급합니다.",


                TermsTitleN:"제 14조(환급)",
                TermsN1:"“몰”은 이용자가 구매한 재화 등을 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.",


                TermsTitleO:"제 15조(청약철회 등)",
                TermsO1:"① “몰”과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날 부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 단순변심으로 인한 청약의 철회는 제한 되며 , 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다. ",
                TermsO2:"② 이용자는 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3일 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.",


                TermsTitleP:"제 16조(청약철회 등의 효과)",
                TermsP1:"“몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.",


                TermsTitleQ:"제 17조(개인정보보호)",
                TermsQ1:"① “몰”은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.",
                TermsQ2:"② “몰”은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.",
                TermsQ3:"③ “몰”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.",
                TermsQ4:"④ “몰”은 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.",
                TermsQ5:"⑤ “몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.",
                TermsQ6:"⑥ 이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “몰”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “몰”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.",
                TermsQ7:"⑦ “몰”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를  최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.",
                TermsQ8:"⑧ “몰” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.",
                TermsQ9:"⑨ “몰”은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.",



                TermsTitleR:"제 18조(“몰“의 의무)",
                TermsR1:"① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.",
                TermsR2:"② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.",
                TermsR3:"③ “몰”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.",
                TermsR4:"④ “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.",


                TermsTitleS:"제 19조(회원의 ID 및 비밀번호에 대한 의무)",
                TermsS1:"① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.",
                TermsS2:"② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.",
                TermsS3:"③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “몰”에 통보하고 “몰”의 안내가 있는 경우에는 그에 따라야 합니다.",


                TermsTitleT:"제 20조(이용자의 의무)",
                TermsT1:"이용자는 다음 행위를 하여서는 안 됩니다.",
                TermsT1_1:"1. 신청 또는 변경시 허위 내용의 등록",
                TermsT1_2:"2. 타인의 정보 도용",
                TermsT1_3:"3. “몰”에 게시된 정보의 변경",
                TermsT1_4:"4. “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시",
                TermsT1_5:"5. “몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해",
                TermsT1_6:"6. “몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위",
                TermsT1_7:"7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위",


                TermsTitleU:"제 21조(저작권의 귀속 및 이용제한)",
                TermsU1:"① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에 귀속합니다.",
                TermsU2:"② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이 귀속된 정보를 “몰”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.",
                TermsU3:"③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.",



                TermsTitleV:"제 22조(분쟁해결)",
                TermsV1:"① “몰”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.",
                TermsV2:"② “몰”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.",
                TermsV3:"③ “몰”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.",


                TermsTitleW:"제 23조(재판권 및 준거법)",
                TermsW1:"① “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.",
                TermsW2:"② “몰”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.",


                TermsTitleX:"이 약관은 2024.6.1 부터 시행합니다."
            }
        }
        function getTermsModal(){
            const language = navigator.language.split("-")[0];
            const text = TermsModalTEXT[language] || TermsModalTEXT.en; // 브라우저 해당언어가 없다면 en(영어)를 반환해라!

            document.querySelector(".terms_modalGroup .terms_head").textContent = text.TermsHead;

            // 제 1조 (A)
            document.querySelector(".terms_modalGroup .termsTitleA").textContent = text.TermsTitleA;
            document.querySelector(".terms_modalGroup .terms_A1").textContent = text.TermsA1;
            document.querySelector(".terms_modalGroup .terms_A2").textContent = text.TermsA2;


            // 제 2조 (B)
            document.querySelector(".terms_modalGroup .termsTitleB").textContent = text.TermsTitleB;
            document.querySelector(".terms_modalGroup .terms_B1").textContent = text.TermsB1;
            document.querySelector(".terms_modalGroup .terms_B2").textContent = text.TermsB2;
            document.querySelector(".terms_modalGroup .terms_B3").textContent = text.TermsB3;
            document.querySelector(".terms_modalGroup .terms_B4").textContent = text.TermsB4;


            // 제 3조 (C)
            document.querySelector(".terms_modalGroup .termsTitleC").textContent = text.TermsTitleC;
            document.querySelector(".terms_modalGroup .terms_C1").textContent = text.TermsC1;
            document.querySelector(".terms_modalGroup .terms_C2").textContent = text.TermsC2;
            document.querySelector(".terms_modalGroup .terms_C3").textContent = text.TermsC3;
            document.querySelector(".terms_modalGroup .terms_C4").textContent = text.TermsC4;
            document.querySelector(".terms_modalGroup .terms_C5").textContent = text.TermsC5;
            document.querySelector(".terms_modalGroup .terms_C6").textContent = text.TermsC6;

            // 제 4조 (D)
            document.querySelector(".terms_modalGroup .termsTitleD").textContent = text.TermsTitleD;
            document.querySelector(".terms_modalGroup .terms_D1").textContent = text.TermsD1;
            document.querySelector(".terms_modalGroup .terms_D1_1").textContent = text.TermsD1_1;
            document.querySelector(".terms_modalGroup .terms_D1_2").textContent = text.TermsD1_2;
            document.querySelector(".terms_modalGroup .terms_D2").textContent = text.TermsD2;
            document.querySelector(".terms_modalGroup .terms_D3").textContent = text.TermsD3;
            document.querySelector(".terms_modalGroup .terms_D4").textContent = text.TermsD4;
        

            // 제 5조 (E)
            document.querySelector(".terms_modalGroup .termsTitleE").textContent = text.TermsTitleE;
            document.querySelector(".terms_modalGroup .terms_E1").textContent = text.TermsE1;
            document.querySelector(".terms_modalGroup .terms_E2").textContent = text.TermsE2;
            document.querySelector(".terms_modalGroup .terms_E3").textContent = text.TermsE3;


            // 제 6조 (F)
            document.querySelector(".terms_modalGroup .termsTitleF").textContent = text.TermsTitleF;
            document.querySelector(".terms_modalGroup .terms_F1").textContent = text.TermsF1;
            document.querySelector(".terms_modalGroup .terms_F2").textContent = text.TermsF2;
            document.querySelector(".terms_modalGroup .terms_F2_1").textContent = text.TermsF2_1;
            document.querySelector(".terms_modalGroup .terms_F2_2").textContent = text.TermsF2_2;
            document.querySelector(".terms_modalGroup .terms_F2_3").textContent = text.TermsF2_3;
            document.querySelector(".terms_modalGroup .terms_F3").textContent = text.TermsF3;
            document.querySelector(".terms_modalGroup .terms_F4").textContent = text.TermsF4;


            // 제 7조 (G)
            document.querySelector(".terms_modalGroup .termsTitleG").textContent = text.TermsTitleG;
            document.querySelector(".terms_modalGroup .terms_G1").textContent = text.TermsG1;
            document.querySelector(".terms_modalGroup .terms_G2").textContent = text.TermsG2;
            document.querySelector(".terms_modalGroup .terms_G2_1").textContent = text.TermsG2_1;
            document.querySelector(".terms_modalGroup .terms_G2_2").textContent = text.TermsG2_2;
            document.querySelector(".terms_modalGroup .terms_G2_3").textContent = text.TermsG2_3;
            document.querySelector(".terms_modalGroup .terms_G2_4").textContent = text.TermsG2_4;
            document.querySelector(".terms_modalGroup .terms_G3").textContent = text.TermsG3;
            document.querySelector(".terms_modalGroup .terms_G4").textContent = text.TermsG4;


            // 제 8조 (H)
            document.querySelector(".terms_modalGroup .termsTitleH").textContent = text.TermsTitleH;
            document.querySelector(".terms_modalGroup .terms_H1").textContent = text.TermsH1;
            document.querySelector(".terms_modalGroup .terms_H2").textContent = text.TermsH2;


            // 제 9조 (I)
            document.querySelector(".terms_modalGroup .termsTitleI").textContent = text.TermsTitleI;
            document.querySelector(".terms_modalGroup .terms_I1").textContent = text.TermsI1;
            document.querySelector(".terms_modalGroup .terms_I1_1").textContent = text.TermsI1_1;
            document.querySelector(".terms_modalGroup .terms_I1_2").textContent = text.TermsI1_2;
            document.querySelector(".terms_modalGroup .terms_I2").textContent = text.TermsI2;
            document.querySelector(".terms_modalGroup .terms_I3").textContent = text.TermsI3;
            document.querySelector(".terms_modalGroup .terms_I4").textContent = text.TermsI4;



            // 제 10조 (J)
            document.querySelector(".terms_modalGroup .termsTitleJ").textContent = text.TermsTitleJ;
            document.querySelector(".terms_modalGroup .terms_J1").textContent = text.TermsJ1;
            document.querySelector(".terms_modalGroup .terms_J1_1").textContent = text.TermsJ1_1;
            document.querySelector(".terms_modalGroup .terms_J1_2").textContent = text.TermsJ1_2;
            document.querySelector(".terms_modalGroup .terms_J1_3").textContent = text.TermsJ1_3;
            document.querySelector(".terms_modalGroup .terms_J2").textContent = text.TermsJ2;
            document.querySelector(".terms_modalGroup .terms_J3").textContent = text.TermsJ3;



            // 제 11조 (K)
            document.querySelector(".terms_modalGroup .termsTitleK").textContent = text.TermsTitleK;
            document.querySelector(".terms_modalGroup .terms_K1").textContent = text.TermsK1;
            document.querySelector(".terms_modalGroup .terms_K1_1").textContent = text.TermsK1_1;
            document.querySelector(".terms_modalGroup .terms_K1_2").textContent = text.TermsK1_2;
            document.querySelector(".terms_modalGroup .terms_K1_3").textContent = text.TermsK1_3;
            document.querySelector(".terms_modalGroup .terms_K1_4").textContent = text.TermsK1_4;
            document.querySelector(".terms_modalGroup .terms_K1_5").textContent = text.TermsK1_5;
            document.querySelector(".terms_modalGroup .terms_K1_6").textContent = text.TermsK1_6;


            // 제 12조 (L)
            document.querySelector(".terms_modalGroup .termsTitleL").textContent = text.TermsTitleL;
            document.querySelector(".terms_modalGroup .terms_L1").textContent = text.TermsL1;
            document.querySelector(".terms_modalGroup .terms_L2").textContent = text.TermsL2;


            // 제 13조 (M)
            document.querySelector(".terms_modalGroup .termsTitleM").textContent = text.TermsTitleM;
            document.querySelector(".terms_modalGroup .terms_M1").textContent = text.TermsM1;


            // 제 14조 (N)
            document.querySelector(".terms_modalGroup .termsTitleN").textContent = text.TermsTitleN;
            document.querySelector(".terms_modalGroup .terms_N1").textContent = text.TermsN1;


            // 제 15조 (O)
            document.querySelector(".terms_modalGroup .termsTitleO").textContent = text.TermsTitleO;
            document.querySelector(".terms_modalGroup .terms_O1").textContent = text.TermsO1;
            document.querySelector(".terms_modalGroup .terms_O2").textContent = text.TermsO2;


            // 제 16조 (P)
            document.querySelector(".terms_modalGroup .termsTitleP").textContent = text.TermsTitleP;
            document.querySelector(".terms_modalGroup .terms_P1").textContent = text.TermsP1;



            // 제 17조 (Q)
            document.querySelector(".terms_modalGroup .termsTitleQ").textContent = text.TermsTitleQ;
            document.querySelector(".terms_modalGroup .terms_Q1").textContent = text.TermsQ1;
            document.querySelector(".terms_modalGroup .terms_Q2").textContent = text.TermsQ2;
            document.querySelector(".terms_modalGroup .terms_Q3").textContent = text.TermsQ3;
            document.querySelector(".terms_modalGroup .terms_Q4").textContent = text.TermsQ4;
            document.querySelector(".terms_modalGroup .terms_Q5").textContent = text.TermsQ5;
            document.querySelector(".terms_modalGroup .terms_Q6").textContent = text.TermsQ6;
            document.querySelector(".terms_modalGroup .terms_Q7").textContent = text.TermsQ7;
            document.querySelector(".terms_modalGroup .terms_Q8").textContent = text.TermsQ8;
            document.querySelector(".terms_modalGroup .terms_Q9").textContent = text.TermsQ9;


            // 제 18조 (R)
            document.querySelector(".terms_modalGroup .termsTitleR").textContent = text.TermsTitleR;
            document.querySelector(".terms_modalGroup .terms_R1").textContent = text.TermsR1;
            document.querySelector(".terms_modalGroup .terms_R2").textContent = text.TermsR2;
            document.querySelector(".terms_modalGroup .terms_R3").textContent = text.TermsR3;
            document.querySelector(".terms_modalGroup .terms_R4").textContent = text.TermsR4;


            // 제 19조 (S)
            document.querySelector(".terms_modalGroup .termsTitleS").textContent = text.TermsTitleS;
            document.querySelector(".terms_modalGroup .terms_S1").textContent = text.TermsS1;
            document.querySelector(".terms_modalGroup .terms_S2").textContent = text.TermsS2;
            document.querySelector(".terms_modalGroup .terms_S3").textContent = text.TermsS3;


            // 제 20조 (T)
            document.querySelector(".terms_modalGroup .termsTitleT").textContent = text.TermsTitleT;
            document.querySelector(".terms_modalGroup .terms_T1").textContent = text.TermsT1;
            document.querySelector(".terms_modalGroup .terms_T1_1").textContent = text.TermsT1_1;
            document.querySelector(".terms_modalGroup .terms_T1_2").textContent = text.TermsT1_2;
            document.querySelector(".terms_modalGroup .terms_T1_3").textContent = text.TermsT1_3;
            document.querySelector(".terms_modalGroup .terms_T1_4").textContent = text.TermsT1_4;
            document.querySelector(".terms_modalGroup .terms_T1_5").textContent = text.TermsT1_5;
            document.querySelector(".terms_modalGroup .terms_T1_6").textContent = text.TermsT1_6;
            document.querySelector(".terms_modalGroup .terms_T1_7").textContent = text.TermsT1_7;


            // 제 21조 (U)
            document.querySelector(".terms_modalGroup .termsTitleU").textContent = text.TermsTitleU;
            document.querySelector(".terms_modalGroup .terms_U1").textContent = text.TermsU1;
            document.querySelector(".terms_modalGroup .terms_U2").textContent = text.TermsU2;
            document.querySelector(".terms_modalGroup .terms_U3").textContent = text.TermsU3;


            // 제 22조 (V)
            document.querySelector(".terms_modalGroup .termsTitleV").textContent = text.TermsTitleV;
            document.querySelector(".terms_modalGroup .terms_V1").textContent = text.TermsV1;
            document.querySelector(".terms_modalGroup .terms_V2").textContent = text.TermsV2;
            document.querySelector(".terms_modalGroup .terms_V3").textContent = text.TermsV3;


            // 제 23조 (W)
            document.querySelector(".terms_modalGroup .termsTitleW").textContent = text.TermsTitleW;
            document.querySelector(".terms_modalGroup .terms_W1").textContent = text.TermsW1;
            document.querySelector(".terms_modalGroup .terms_W2").textContent = text.TermsW2;


            // 시행기간 
            document.querySelector(".terms_modalGroup .termsTitleX").textContent = text.TermsTitleX;
        }
        getTermsModal();
    } else {
        TermsModalText.style.display="none";
    }
}
if(TermsModalText){
    window.addEventListener('load', termsModalTextJS);
    window.addEventListener('hashchange', termsModalTextJS);
}