// <!-- 모바일 Bottom JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 모바일 bottom / 아이콘 색상변경 ]
function mobileBottomJS() {
  const MobileBottom = document.querySelector('.mobileBottom');
  const ValidHashes = ['#home', '#search', '#users', '#mobilesearch', '#videodetail'];
  const Hash = window.location.hash;
  const MobileBottomHomeIcon = document.querySelector('.mobileBottom-home i');
  const MobileBottomSearchIcon = document.querySelector('.mobileBottom-search i');
  const MobileBottomUserAvatar = document.querySelector('.mobileBottom-userAvatar');

  // PC 화면일 경우 모바일 하단 바 숨김
  if (window.innerWidth > 1000) {
    MobileBottom.style.display = 'none';
    return;
  }

  // 해시가 유효한 경우
  const IsValidHash = ValidHashes.some(validHash => Hash.includes(validHash));
  if (!Hash || IsValidHash) {

    // 비디오 상세 페이지일 때
    if(!Hash || Hash.includes('#home')){
      MobileBottom.style.backgroundColor = "white";
      MobileBottomHomeIcon.style="color:black";
      MobileBottomSearchIcon.style="color:rgb(141, 141, 141);";
      MobileBottomUserAvatar.style="padding:0px; border:0px solid black;";
      MobileBottom.style.display = 'block';
    } else if(Hash.includes('#mobilesearch')){
      MobileBottom.style.backgroundColor = "white";
      MobileBottomHomeIcon.style="color:rgb(141, 141, 141);";
      MobileBottomSearchIcon.style="color:black";
      MobileBottomUserAvatar.style="padding:0px; border:0px solid black;";
      MobileBottom.style.display = 'block';
    } else if(Hash.includes('#users')){
      MobileBottom.style.backgroundColor = "white";
      MobileBottomHomeIcon.style="color:rgb(141, 141, 141);";
      MobileBottomSearchIcon.style="color:rgb(141, 141, 141);";
      MobileBottomUserAvatar.style="padding:2px; border:1px solid black";
      MobileBottom.style.display = 'block';
    } else if(Hash.includes('#search')){
      MobileBottom.style.backgroundColor = "white";
      MobileBottomHomeIcon.style="color:rgb(141, 141, 141);";
      MobileBottomSearchIcon.style="color:rgb(141, 141, 141);";
      MobileBottomUserAvatar.style="padding:0px; border:0px solid black;";
      setTimeout(() => {
        MobileBottom.style.display = 'block';
      }, 300);
    } else {
      MobileBottom.style.backgroundColor = "white";
      MobileBottomHomeIcon.style="color:rgb(141, 141, 141);";
      MobileBottomSearchIcon.style="color:rgb(141, 141, 141);";
      MobileBottomUserAvatar.style="padding:0px; border:0px solid black;";
      MobileBottom.style.display = 'block';
    }
  } else {
    MobileBottom.style.display = 'none';
  }
}
if (UserID) {
  window.addEventListener('hashchange', mobileBottomJS);
  window.addEventListener('load', mobileBottomJS);
  window.addEventListener('resize', mobileBottomJS);
}