// <!-- PC 업로드 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 비디오업로드 언어번역 ]
function getVideoUpload(){
  const VideoUploadSubmit = document.querySelector(".videoUpload_submit");
  const SubmitText = {
    en: { VideoUploadSubmit: "Upload"},
    ko: { VideoUploadSubmit: "업로드"}
  }
  const Language = navigator.language.split("-")[0];
  const Text = SubmitText[Language] || SubmitText.en;
  if (VideoUploadSubmit !== null) VideoUploadSubmit.textContent = Text.VideoUploadSubmit;
}
if(UserID){
  getVideoUpload();
}






//// [ 비디오업로드 보이기,숨기기 / 비디오업로드 값 초기화 ]
function videoUploadJS() {
  const VideoUpload = document.querySelector(".videoUpload");
  const VideoUploadChoice = document.querySelector('.videoUpload-Choice');
  const VideoChoice = document.querySelector('.videoChoice');
  const VideoTag = document.querySelector('.videoTag');
  const VideoChoiceInput = document.querySelector('.videoChoice_input'); 
  const PlusIcon = document.querySelector('.videoChoice_plusIcon');
  const PlusIconLoading = document.querySelector(".plusIconLoading");
  const ThumbnailChoice = document.querySelector('.thumbnailChoice');
  const ThumbnailTag = document.querySelector('.thumbnailTag');
  const ThumbnailChoiceInput = document.querySelector('.thumbnailChoice_input');
  const ThumbnailAdd = document.querySelector('.thumbnailAdd');
  const VideoUploadTitle = document.querySelector(".videoUpload_title");
  const VideoUploadDescription = document.querySelector(".videoUpload_description");
  if(window.location.hash === `#videoupload/${UserID.innerText}`){
    VideoUpload.style.display="flex";
    if (window.innerWidth <= 540) {
      VideoChoiceInput.click();
    }
  } else {
    VideoUpload.style.display="none";

    // 비디오,썸네일 부모요소
    VideoUploadChoice.style.display = "block";

    // 비디오 선택부분
    VideoChoice.style.display = "flex";
    VideoTag.src = "";
    VideoChoiceInput.value = "";
    VideoTag.style.display = "none";
    PlusIcon.style.display = "grid";
    PlusIconLoading.style.display = "none";


    // 썸네일 선택부분
    ThumbnailChoice.style.display = "none";
    ThumbnailTag.src = "";
    ThumbnailChoiceInput.value = "";
    ThumbnailTag.style.display = "none";
    ThumbnailAdd.style.display = "none";


    // 제목,설명
    VideoUploadTitle.value = "";
    VideoUploadDescription.value = "";
  }
}
if(UserID){
  window.addEventListener('hashchange', videoUploadJS);
  window.addEventListener('load', videoUploadJS);
}









//// [ 비디오 가져오기 ] 
function videoChange() {
  const VideoTag = document.querySelector('.videoTag');
  const PlusIcon = document.querySelector('.videoChoice_plusIcon');
  const VideoChoiceInput = document.querySelector('.videoChoice_input');
  const ThumbnailAdd = document.querySelector('.thumbnailAdd');
  const PlusIconLoading = document.querySelector(".plusIconLoading");

  function handlePlayerClick() {
    VideoChoiceInput.click();
  }

  const handleVideoChange = (event) => {
    PlusIcon.style.display = "none";
    PlusIconLoading.style.display = "block";
    const File = event.target.files[0];
    if (File) {
      const reader = new FileReader();

      // 비디오 파일을 읽었을 때 실행되는 함수
      reader.onload = function(e) {
        VideoTag.src = e.target.result;
        VideoTag.style.display = "block";
        ThumbnailAdd.style.display = "flex";
        PlusIconLoading.style.display = "none";
      };
  
      // 비디오 파일 읽기
      reader.readAsDataURL(File);
    }
  };

  // 기존 이벤트 제거
  PlusIcon.removeEventListener('click', handlePlayerClick);
  VideoChoiceInput.removeEventListener('change', handleVideoChange);
  
  // 이벤트 등록
  PlusIcon.addEventListener('click', handlePlayerClick);
  VideoChoiceInput.addEventListener('change', handleVideoChange);
}
if(UserID){
  videoChange();
}




//// [ 썸네일 가져오기 ]
function thumbnailChange() {
  const VideoChoice = document.querySelector('.videoChoice');
  const VideoTag = document.querySelector('.videoTag');
  const ThumbnailTag = document.querySelector('.thumbnailTag');
  const ThumbnailChoice = document.querySelector('.thumbnailChoice');
  const ThumbnailChoiceInput = document.querySelector('.thumbnailChoice_input');
  const ThumbnailAddText = document.querySelector('.thumbnailAddText');

  function thumbnailAddClick() {
    ThumbnailChoiceInput.click();
  }

  const handleThumbnailChange = (event) => {
    const File = event.target.files[0];
    if (File) {
      const reader = new FileReader();

      // 비디오 파일을 읽었을 때 실행되는 함수
      reader.onload = function(e) {
        ThumbnailTag.src = e.target.result;
        VideoChoice.style.display = "none";
        ThumbnailTag.style.display = "block";
        VideoTag.style.display = "none";
        ThumbnailChoice.style.display = "flex";
      };
  
      // 비디오 파일 읽기
      reader.readAsDataURL(File);
    } else {
      // 취소 시: 기본 상태로 UI 초기화
      ThumbnailTag.src = "";
      VideoChoice.style.display = "flex";
      ThumbnailTag.style.display = "none";
      VideoTag.style.display = "block";
      ThumbnailChoice.style.display = "none";
    }
  };

  // 기존 이벤트 제거
  ThumbnailTag.removeEventListener('click', thumbnailAddClick);
  ThumbnailAddText.removeEventListener('click', thumbnailAddClick);
  ThumbnailChoiceInput.removeEventListener('change', handleThumbnailChange);
  
  // 이벤트 등록
  ThumbnailTag.addEventListener('click', thumbnailAddClick);
  ThumbnailAddText.addEventListener('click', thumbnailAddClick);
  ThumbnailChoiceInput.addEventListener('change', handleThumbnailChange);
}
if(UserID){
  thumbnailChange();
}






//// [ 제목,설명 포커스 상태에 따라 보이기,숨기기 ] 
function updateVisibility() {
  const VideoUploadTitle = document.querySelector('.videoUpload_title');
  const VideoUploadDescription = document.querySelector('.videoUpload_description');
  const VideoUploadChoice = document.querySelector('.videoUpload-Choice');
  
  // 화면 크기가 540px 이하일 때
  if (window.innerWidth <= 540) {
    if (
      document.activeElement === VideoUploadTitle || // 제목 입력 필드에 포커스가 있거나
      document.activeElement === VideoUploadDescription // 설명 입력 필드에 포커스가 있을 때
    ) {
      // 요소들을 숨김
      VideoUploadChoice.style.display = 'none';
    } else {
      // 요소들을 다시 표시
      VideoUploadChoice.style.display = 'block';
    }
  } else {
    // 화면 크기가 540px 초과일 경우 모든 요소를 항상 표시
    VideoUploadChoice.style.display = 'block';
  }
}
function toInputs() {
  const VideoUploadTitle = document.querySelector('.videoUpload_title');
  const VideoUploadDescription = document.querySelector('.videoUpload_description');

  // focus와 blur 이벤트에 대해 updateVisibility 함수 연결
  [VideoUploadTitle, VideoUploadDescription].forEach((Input) => {
    Input.addEventListener('focus', updateVisibility);
    Input.addEventListener('blur', updateVisibility);
  });
}
if(UserID){
  window.addEventListener('load', toInputs);
  window.addEventListener('load', updateVisibility);
  window.addEventListener('resize', updateVisibility);
}








//// [ 비디오 업로드 프로튼 ]
// 참고: function videoUploadSubmit() 앞에 async 키워드를 붙이는 이유는 그 함수 내에서 await를 사용하여 비동기 작업의 완료를 기다리고, 순차적으로 코드를 실행할 수 있게 하기 위함입니다.
async function videoUploadSubmit(){
  const VideoInput = document.querySelector(".videoChoice_input").files[0];
  const ThumbnailInput = document.querySelector('.thumbnailChoice_input').files[0];
  const VideoUploadTitle = document.querySelector(".videoUpload_title").value;
  const VideoUploadDescription = document.querySelector(".videoUpload_description").value;
  const Language = navigator.language.split("-")[0];

  const VideoData = {
    videoName: VideoInput.name,
    videoContentType: VideoInput.type,
    thumbnailName: ThumbnailInput ? ThumbnailInput.name : null,
    thumbnailContentType: ThumbnailInput ? ThumbnailInput.type : null
  };

  try {
    const VideoPresignedRes = await fetch(`/videos/videoPresigned`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(VideoData)
    });

    if (!VideoPresignedRes.ok) {
      throw new Error('Pre-signed URL 요청 실패');
    }

    const { urls, s3VideoURLS, thumbnailURLS } = await VideoPresignedRes.json();


    // S3에 파일을 업로드하는 함수
    async function videoUploadToS3(url, file) {
      const Response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,  // 파일 타입 설정
        },
        body: file,  // 업로드할 파일 데이터
      });

      if (!Response.ok) {
        throw new Error(`파일 업로드 실패: ${file.name}`);
      }
    }


    // 썸네일 리사이징 함수 (넓이만 300으로 설정)
    async function thumbnailResizeImg(imageFile, maxWidth) {
      const img = new Image();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      return new Promise((resolve, reject) => {
        img.onload = () => {
          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(blob);
          }, imageFile.type);
        };

        img.onerror = (error) => reject(error);
        img.src = URL.createObjectURL(imageFile);
      });
    }


    // 병렬로 업로드 수행
    const uploadPromises = [];

    if (urls.thumbnail && ThumbnailInput) {
      const resizedThumbnailBlob = await thumbnailResizeImg(ThumbnailInput, 300);
      uploadPromises.push(videoUploadToS3(urls.thumbnail, resizedThumbnailBlob));
    }

    if (urls.video) {
      uploadPromises.push(videoUploadToS3(urls.video, VideoInput));
    }

    // 모든 업로드가 완료될 때까지 대기
    await Promise.all(uploadPromises);


    // S3 업로드가 완료된 후 '/api/videoUpload'로 데이터 전송
    const VideoUploadRes = await fetch('/videos/videoUpload', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        s3VideoUrl:s3VideoURLS,
        thumbnailUrl:thumbnailURLS,
        title:VideoUploadTitle,
        description:VideoUploadDescription,
        videoLanguage:Language
      })
    });

    if (!VideoUploadRes.ok) {
      throw new Error('비디오 업로드 중 오류 발생');
    }

    const { videoId } = await VideoUploadRes.json();
    if (videoId) {
      window.location.reload();
    } else {
      throw new Error('videoId를 받지 못했습니다.');
    }

  } catch (error) {
    console.error('업로드 중 오류 발생:', error);
  }
}




//// [ 비디오 업로드 벨류 체크 ]
function checkValue(){
  const VideoInput = document.querySelector(".videoChoice_input").files[0];
  const VideoUploadTitle = document.querySelector(".videoUpload_title").value;
  if(!VideoInput){
    alert("비디오를 선택하여 주세요.");
  }else if(!VideoUploadTitle){
    alert("제목을 입력하여 주세요.");
  }else{
    videoUploadSubmit();
  }
}
if(UserID){
  const VideoUploadSubmit = document.querySelector(".videoUpload_submit");
  VideoUploadSubmit.addEventListener('click', checkValue);
}