//  <!-- 모바일 뒤로가기 JAVASCRIPT -->
const UserID = document.querySelector(".userID");

//// [ 모바일 뒤로가기 ]
function mobileBackJS(){
    const MobileBack = document.querySelector('.mobileBack');
    const Hash = window.location.hash;
    const validHashes = ['#edit-profile', '#change-password', '#withdrawal', '#inquiryupload', '#videoEdit' ];
    if (window.innerWidth > 1000) {
        MobileBack.style = 'display:none';
    } else if (validHashes.some(validHash => Hash.includes(validHash))) {
        MobileBack.style = 'display:flex;';
    } else {
        MobileBack.style = 'display:none';
    }
}
if(UserID){
    window.addEventListener('hashchange', mobileBackJS);
    window.addEventListener('load', mobileBackJS);
    window.addEventListener('resize', mobileBackJS);
    document.getElementById('mobileBackIcon').addEventListener('click', function() {
      window.history.back();
    });
}