//  <!-- 공통부분 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 전체 스크롤 해시 / 로드 ]
const BodyContent = document.querySelector('.bodyContent');
function hideScrollbar() {
  document.body.style.overflow = 'hidden';
}
function showScrollbar() {
  document.body.style.overflow = 'auto';
}
function scrollJS(){
  if(BodyContent){
    BodyContent.scrollTop = 0;
  }
  hideScrollbar();
  document.body.scrollIntoView();
  showScrollbar();
}
window.addEventListener('hashchange', scrollJS);
window.addEventListener('load', scrollJS);