// <!-- 개인정보처리방침 로그인 모달창 JAVASCRIPT -->
const PrivacyModalText = document.querySelector(".privacy_modalText");

//// [ 개인정보처리방침 로그인 모달창 언어번역, 보이기/숨기기 ]
function privacyModalTextJS(){
    if(window.location.hash === "#signup"){
        PrivacyModalText.style.display="flex";
        
        const PrivacyModalTEXT = {
            en: {
                PrivacyHead: "Privacy Policy",
                PrivacyHeadTitle: "TozJam (hereinafter referred to as the 'Company') establishes and discloses the following privacy policy to protect the personal information of information subjects in accordance with Article 30 of the Personal Information Protection Act and to promptly and smoothly process related complaints.",


                PrivacyTitleA: "Article 1 (Purpose of processing personal information)",
                PrivacyA1: "The Company processes personal information for the following purposes. The personal information processed for each purpose shall not be used for any other purpose, and in the event that the purpose of use is changed, the Company will take necessary measures such as obtaining separate consent in accordance with Article 18 of the Personal Information Protection Act.",
                PrivacyA2: "1. Website membership registration and management",
                PrivacyA3: "The Company processes personal information for the purpose of confirming the intention to register as a member, authenticating and identifying members, maintaining and managing membership qualifications, verifying identity in accordance with the limited identity verification system, preventing misuse of services, confirming the consent of legal representatives for the processing of personal information of children under 14 years of age, various notifications, handling complaints, etc.",
                PrivacyA4: "2. Provision of goods or services",
                PrivacyA5: "The Company processes personal information for the purpose of providing services, sending contracts and bills, providing content, providing customized services, processing payments and settlements, and collecting receivables, etc.",
                PrivacyA6: "3. Handling complaints",
                PrivacyA7: "The Company processes personal information for the purpose of confirming the identity of complainants, confirming complaints, notifying them of contacts for factual investigations, and notifying them of processing results.",


                PrivacyTitleB: "Article 2 (Retention and use period of personal information)",
                PrivacyB1: "① The Company retains and uses personal information within the period of retention and use agreed upon when collecting personal information from the information subject, or within the period of retention and use required under relevant laws and regulations.",
                PrivacyB2: "② The retention and use period for each personal information processing is as follows:",
                PrivacyB3: "1. Website membership registration and management: Until withdrawal from the website",
                PrivacyB4: "However, in cases falling under the following subparagraphs, until the end of the relevant reason:",
                PrivacyB5: "1) In case an investigation or inspection is underway due to a violation of relevant laws and regulations, until the end of the investigation or inspection",
                PrivacyB6: "2) In case there are outstanding rights and obligations related to website usage, until the settlement of the relevant rights and obligations",
                PrivacyB7: "2. Provision of goods or services: Until the supply of goods or services is completed and payment and settlement are completed",
                PrivacyB8: "However, in cases falling under the following subparagraphs, until the end of the relevant period:",
                PrivacyB9: "1) Records concerning indication/advertising, contract content, performance, etc. of transactions pursuant to the Act on Consumer Protection in Electronic Commerce, etc.",
                PrivacyB10: "- Records on indication/advertising: 6 months",
                PrivacyB11: "- Records on contracts or subscription withdrawals, payment of fees, supply of goods, etc.: 5 years",
                PrivacyB12: "- Records on consumer complaints or dispute resolution: 3 years",
                PrivacyB13: "2) Records on confirmation of telecommunications fact pursuant to the Telecommunications Basic Act",
                PrivacyB14: "- Records on dates and times of telecommunication, start/end times, counterparty subscriber numbers, frequency of use, and locations of telecommunication bases: 1 year",
                PrivacyB15: "- Records on internet logs, access points, etc.: 3 months",


                PrivacyTitleC: "Article 3 (Outsourcing of personal information processing)",
                PrivacyC1: "The Company may entrust the processing of personal information to external parties for the purpose of improving services, and stipulates that personal information is safely managed in accordance with the provisions of relevant laws and regulations when entering into outsourcing contracts.",
                PrivacyC2: "Outsourced company: Toss Payments",
                PrivacyC3: "Outsourced business contents: Payment agency service",
                PrivacyC4: "Retention and use period of personal information: Until withdrawal of membership or termination of outsourced business (in cases where it is necessary to retain pursuant to relevant laws and regulations or where consent has been obtained for the retention period)",


                PrivacyTitleD: "Article 4 (Rights of users and legal representatives, and methods of exercise)",
                PrivacyD1: "① Information subjects may exercise the following personal information protection rights against the Company at any time:",
                PrivacyD2: "1. Request for access to personal information",
                PrivacyD3: "2. Request for correction in case of errors",
                PrivacyD4: "3. Request for deletion",
                PrivacyD5: "4. Request for suspension of processing",
                PrivacyD6: "② The exercise of rights under paragraph 1 may be made to the Company in writing, by telephone, by electronic mail, by facsimile, etc., and the Company will take measures promptly.",
                PrivacyD7: "③ If an information subject requests correction or deletion of personal information, the Company will not use or provide the relevant personal information until correction or deletion is completed.",
                PrivacyD8: "④ The exercise of rights under paragraph 1 may be made through a legal representative or a delegated person. In this case, an authorization letter according to Annex No. 11 of the Enforcement Rules of the Personal Information Protection Act must be submitted.",
                PrivacyD9: "⑤ Information subjects shall not infringe upon the personal information and privacy of themselves or others processed by the Company in violation of relevant laws such as the Personal Information Protection Act.",



                PrivacyTitleE: "Article 5 (Items of personal information processed)",
                PrivacyE1: "The Company processes the following personal information:",
                PrivacyE2: "1. Website membership registration and management",
                PrivacyE3: "Required items: Name, email address, password, phone number",
                PrivacyE4: "2. Provision of goods or services",
                PrivacyE5: "Required items: Name, email address, password, phone number",
                PrivacyE6: "Optional items: Past payment history",
                PrivacyE7: "3. The following personal information items may be automatically generated and collected during the process of using internet services:",
                PrivacyE8: "IP address, cookies, MAC address, service usage records, visit records, records of improper use, etc.",


                PrivacyTitleF: "Article 6 (Destruction of personal information)",
                PrivacyF1: "① When personal information becomes unnecessary due to the expiration of the retention period or achievement of the processing purpose, the Company shall promptly destroy the relevant personal information.",
                PrivacyF2: "② If personal information must be retained pursuant to other laws and regulations despite the expiration of the agreed retention period or achievement of the processing purpose, the Company shall move the personal information to a separate database (DB) or store it separately.",
                PrivacyF3: "③ The procedure and method of destruction of personal information are as follows:",
                PrivacyF4: "1. Destruction procedure",
                PrivacyF5: "The Company selects personal information for destruction, and with the approval of the person in charge of personal information protection, destroys the personal information.",
                PrivacyF6: "2. Destruction method",
                PrivacyF7: "Personal information recorded and stored in electronic file format is destroyed using a method that makes it impossible to regenerate the record, such as low-level format (Low Level Format). Personal information recorded and stored on paper documents is destroyed using a shredder or incinerator.",


                PrivacyTitleG: "Article 7 (Measures for securing the safety of personal information)",
                PrivacyG1: "The Company takes the following measures to secure the safety of personal information:",
                PrivacyG2: "1. Administrative measures: Establishment and implementation of internal management plans, regular employee training, etc.",
                PrivacyG3: "2. Technical measures: Access control of personal information processing systems, installation of access control systems, encryption of unique identification information, installation of security programs, etc.",
                PrivacyG4: "3. Physical measures: Access control of computer rooms, data storage rooms, etc.",


                PrivacyTitleH: "Article 8 (Installation, operation, and refusal of automatic collection devices for personal information)",
                PrivacyH1: "① The Company uses 'cookies' to store and retrieve user information to provide customized services to individual users.",
                PrivacyH2: "② Cookies are small pieces of information sent by the server (HTTP) used to operate websites to the user's computer browser and may be stored on the hard disk of the user's computer.",
                PrivacyH3: "1. Purpose of using cookies: Cookies are used to understand the visit and usage patterns of users on each service and website visited by users, popular search terms, and security access status to provide optimized information to users.",
                PrivacyH4: "2. Installation, operation, and refusal of cookies: Users can refuse to store cookies by setting options in the 'Tools > Internet Options > Privacy' menu of their web browser.",
                PrivacyH5: "3. Refusal to store cookies may cause difficulties in using customized services.",


                PrivacyTitleI: "Article 9 (Personal Information Protection Manager)",
                PrivacyI1: "① The Company is responsible for the overall handling of personal information processing and designates and discloses a personal information protection manager as follows to handle complaints and remedies related to the processing of personal information:",
                PrivacyI2: "▶ Personal Information Protection Manager",
                PrivacyI3: "Name: Hyeonki An",
                PrivacyI4: "Position: CEO",
                PrivacyI5: "Contact: 010-3475-5612 / ponigress@gmail.com",
                PrivacyI6: "② Information subjects may contact the personal information protection manager for inquiries, complaints, and remedies regarding all personal information protection-related matters arising from the use of the Company's services (or businesses), and the Company will respond promptly.",


                PrivacyTitleJ: "Article 10 (Request for access to personal information)",
                PrivacyJ1: "Information subjects may request access to personal information pursuant to Article 35 of the Personal Information Protection Act to the following contact person for receipt and processing. The Company will endeavor to promptly process information subjects' requests for access to personal information.",
                PrivacyJ2: "▶ Contact person for request for access to personal information",
                PrivacyJ3: "Contact: Hyeonki An",
                PrivacyJ4: "Contact: 010-3475-5612 / ponigress@gmail.com",

                PrivacyTitleK: "Article 11 (Remedy for infringements of rights)",
                PrivacyK1: "Information subjects may contact the following organizations for damage relief, counseling, etc., regarding infringements of personal information:",
                PrivacyK2: "▶ Personal Information Infringement Reporting Center (Operated by the Korea Internet & Security Agency)",
                PrivacyK3: "- Responsibilities: Reporting of personal information infringement facts, application for counseling",
                PrivacyK4: "- Website: Privacy.kisa.or.kr",
                PrivacyK5: "- Telephone: 118 (no area code)",
                PrivacyK6: "- Address: 3rd floor, 9 Jinheung-gil, Naju-si, Jeollanam-do, 58324, Korea",
                PrivacyK7: "▶ Personal Information Dispute Mediation Committee",
                PrivacyK8: "- Responsibilities: Application for personal information dispute mediation, group dispute mediation (civil resolution)",
                PrivacyK9: "- Website: www.kopico.go.kr",
                PrivacyK10: "- Telephone: 1833-6972 (no area code)",
                PrivacyK11: "- Address: 4th floor, Government Seoul Building, 209 Sejong-daero, Jongno-gu, Seoul, 03171, Korea",
                PrivacyK12: "▶ Cyber Crime Investigation Department of the Supreme Prosecutors' Office: 02-3480-3573 (www.spo.go.kr)",
                PrivacyK13: "▶ Cyber Safety Division of the National Police Agency: 182 (http://cyberbureau.police.go.kr)",

                
                PrivacyTitleL: "Article 12 (Enforcement and amendment of the privacy policy)",
                PrivacyDate: "This privacy policy is effective from January 1, 2024."
            },
            ko: {   // 한국어
                PrivacyHead: "개인정보 처리방침",
                PrivacyHeadTitle:"포니그레스(이하 ‘회사’라 한다)은 개인정보 보호법 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다.",
                
                PrivacyTitleA: "제 1조 (개인정보의 처리목적)",
                PrivacyA1: "회사는 다음의 목적을 위하여 개인정보를 처리합니다 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다",
                PrivacyA2: "1. 홈페이지 회원 가입 및 관리",
                PrivacyA3: "회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정 이용 방지, 만 14세 미만 아동의 개인정보처리 시 법정대리인의 동의 여부 확인, 각종 고지․통지, 고충 처리 등을 목적으로 개인정보를 처리합니다",
                PrivacyA4: "2. 재화 또는 서비스 제공",
                PrivacyA5: "서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 요금 결제 및 정산, 채권추심 등을 목적으로 개인정보를 처리합니다",
                PrivacyA6: "3. 고충 처리",
                PrivacyA7: "민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리 결과 통보 등의 목적으로 개인정보를 처리합니다",


                PrivacyTitleB: "제 2조 (개인정보의 처리 및 보유기간)",
                PrivacyB1: "① 회사는 법령에 따른 개인정보 보유, 이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용 기간 내에서 개인정보를 처리, 보유합니다",
                PrivacyB2: "② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다",
                PrivacyB3: "1. 홈페이지 회원 가입 및 관리 : 홈페이지 탈퇴 시까지",
                PrivacyB4: "다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지",
                PrivacyB5: "1) 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료 시까지",
                PrivacyB6: "2) 홈페이지 이용에 따른 채권 및 채무관계 잔존 시에는 해당 채권, 채무 관계 정산 시까지",
                PrivacyB7: "2. 재화 또는 서비스 제공 : 재화․서비스 공급완료 및 요금결제․정산 완료 시까지",
                PrivacyB8: "다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지",
                PrivacyB9: "1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시․광고, 계약내용 및 이행 등 거래에 관한 기록",
                PrivacyB10: "- 표시․광고에 관한 기록 : 6개월",
                PrivacyB11: "- 계약 또는 청약 철회, 대금결제, 재화 등의 공급기록 : 5년",
                PrivacyB12: "- 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년",
                PrivacyB13: "2) 「통신비밀보호법」 제41조에 따른 통신사실확인자료 보관",
                PrivacyB14: "- 가입자 전기통신일시, 개시․종료 시간, 상대방 가입자 번호, 사용도수, 발신기지국 위치추적자료 : 1년",
                PrivacyB15: "- 컴퓨터 통신, 인터넷 로그 기록자료, 접속지 추적자료 : 3개월",


                PrivacyTitleC: "제 3조 개인정보의 처리 위탁",
                PrivacyC1: "회사는 서비스 향상을 위해서 이용자의 개인정보를 외부에 수집/취급관리 등을 위탁하여 처리할 수 있으며, 관련 법규의 규정에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 규정하고 있습니다",
                PrivacyC2: "수탁업체: 토스페이먼츠",
                PrivacyC3: "위탁 업무내용: 결제대행 서비스",
                PrivacyC4: "개인정보의 보유및 이용기간: 회원 탈퇴시 또는 위탁 업무 종료시 (관계 법령의 규정에 의하여 보존할 필요가 있는 경우 및 사전 동의를 받은 경우 해당 보유 기간)",



                PrivacyTitleD: "제 4조(이용자 및 법정대리인의 권리와 그 행사 방법)",
                PrivacyD1: "① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다",
                PrivacyD2: "1. 개인정보 열람 요구",
                PrivacyD3: "2. 오류 등이 있을 경우 정정 요구",
                PrivacyD4: "3. 삭제요구",
                PrivacyD5: "4. 처리정지 요구",
                PrivacyD6: "② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다",
                PrivacyD7: "③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다",
                PrivacyD8: "④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다",
                PrivacyD9: "⑤ 정보주체는 개인정보 보호법 등 관계 법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니 됩니다",



                PrivacyTitleE: "제 5조(처리하는 개인정보 항목)",
                PrivacyE1: "회사는 다음의 개인정보 항목을 처리하고 있습니다",
                PrivacyE2: "1. 홈페이지 회원 가입 및 관리",
                PrivacyE3: "필수항목 : 성명,이메일주소,비밀번호,전화번호",
                PrivacyE4: "2. 재화 또는 서비스 제공",
                PrivacyE5: "필수항목 : 성명,이메일주소,비밀번호,전화번호",
                PrivacyE6: "선택항목 : 과거 결제내역",
                PrivacyE7: "3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다",
                PrivacyE8: "IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등",



                PrivacyTitleF: "제 6조(개인정보의 파기)",
                PrivacyF1: "① 회사는 개인정보 보유 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다",
                PrivacyF2: "② 정보주체로부터 동의받은 개인정보 보유 기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다",
                PrivacyF3: "③ 개인정보 파기의 절차 및 방법은 다음과 같습니다",
                PrivacyF4: "1. 파기 절차",
                PrivacyF5: "회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다",
                PrivacyF6: "2. 파기 방법",
                PrivacyF7: "회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다",



                PrivacyTitleG: "제 7조(개인정보의 안전성 확보조치)",
                PrivacyG1: "회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 하고 있습니다",
                PrivacyG2: "1. 관리적 조치 : 내부관리계획 수립 및 시행, 정기적 직원 교육 등",
                PrivacyG3: "2. 기술적 조치 : 개인정보처리시스템 등의 접근 권한 관리, 접근통제시스템 설치, 고유 식별정보 등의 암호화, 보안프로그램 설치",
                PrivacyG4: "3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제",


                PrivacyTitleH: "제 8조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)",
                PrivacyH1: "① 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다",
                PrivacyH2: "② 쿠키는 웹사이트를 운영하는데 이용되는 서버(httP)가 이용자의 컴퓨터 브라우저에 보내는 소량의 정보이며 이용자들의 컴퓨터 내의 하드디스크에 저장되기도 합니다",
                PrivacyH3: "1. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다",
                PrivacyH4: "2. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다",
                PrivacyH5: "3. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다",


                PrivacyTitleI: "제 9조(개인정보 보호책임자)",
                PrivacyI1: "① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만 처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다",
                PrivacyI2: "▶ 개인정보 보호책임자",
                PrivacyI3: "성명 : 안현기",
                PrivacyI4: "직책 : 대표",
                PrivacyI5: "연락처 : 010 6773 5612 / ponigress@gmailcom",
                PrivacyI6: "② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만 처리, 피해구제 등에 관한 사항을 개인정보 보호책임자로 문의하실 수 있습니다 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다",


                PrivacyTitleJ: "제 10조(개인정보 열람청구)",
                PrivacyJ1: "정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 접수․처리 담당자에 요청 할 수 있습니다 회사는 정보주체의 개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다",
                PrivacyJ2: "▶ 개인정보 열람청구 접수․처리 담당자",
                PrivacyJ3: "담당자 : 안현기",
                PrivacyJ4: "연락처 : 010 6773 5612 / ponigress@gmailcom",


                PrivacyTitleK: "제 11조(권익침해 구제 방법)",
                PrivacyK1: "정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다",
                PrivacyK2: "▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)",
                PrivacyK3: "- 소관 업무 : 개인정보 침해사실 신고, 상담 신청",
                PrivacyK4: "- 홈페이지 : Privacykisaorkr",
                PrivacyK5: "- 전화 : (국번없이) 118",
                PrivacyK6: "- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터",
                PrivacyK7: "▶ 개인정보 분쟁조정위원회",
                PrivacyK8: "- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)",
                PrivacyK9: "- 홈페이지 : wwwkoPicogokr",
                PrivacyK10: "- 전화 : (국번없이) 1833-6972",
                PrivacyK11: "- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층",
                PrivacyK12: "▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (wwwsPogokr)",
                PrivacyK13: "▶ 경찰청 사이버안전국 : 182 (httP://cyberbureauPolicegokr)",


                PrivacyTitleL: "제 12조(개인정보 처리방침 시행 및 변경)",
                PrivacyDate: "이 개인정보 처리방침은 2024.6.1 부터 적용됩니다"
            }
        }
        function getModalPrivacy(){
            const language = navigator.language.split("-")[0];
            const text = PrivacyModalTEXT[language] || PrivacyModalTEXT.en; // 브라우저 해당언어가 없다면 en(영어)를 반환해라!

            document.querySelector(".privacy_modalGroup .privacy_head").textContent = text.PrivacyHead;
            document.querySelector(".privacy_modalGroup .privacy_headTitle").textContent = text.PrivacyHeadTitle;

            // 제 1조 (A)
            document.querySelector(".privacy_modalGroup .privacyTitleA").textContent = text.PrivacyTitleA;
            document.querySelector(".privacy_modalGroup .privacy_A1").textContent = text.PrivacyA1;
            document.querySelector(".privacy_modalGroup .privacy_A2").textContent = text.PrivacyA2;
            document.querySelector(".privacy_modalGroup .privacy_A3").textContent = text.PrivacyA3;
            document.querySelector(".privacy_modalGroup .privacy_A4").textContent = text.PrivacyA4;
            document.querySelector(".privacy_modalGroup .privacy_A5").textContent = text.PrivacyA5;
            document.querySelector(".privacy_modalGroup .privacy_A6").textContent = text.PrivacyA6;
            document.querySelector(".privacy_modalGroup .privacy_A7").textContent = text.PrivacyA7;


            // 제 2조 (B)
            document.querySelector(".privacy_modalGroup .privacyTitleB").textContent = text.PrivacyTitleB;
            document.querySelector(".privacy_modalGroup .privacy_B1").textContent = text.PrivacyB1;
            document.querySelector(".privacy_modalGroup .privacy_B2").textContent = text.PrivacyB2;
            document.querySelector(".privacy_modalGroup .privacy_B3").textContent = text.PrivacyB3;
            document.querySelector(".privacy_modalGroup .privacy_B4").textContent = text.PrivacyB4;
            document.querySelector(".privacy_modalGroup .privacy_B5").textContent = text.PrivacyB5;
            document.querySelector(".privacy_modalGroup .privacy_B6").textContent = text.PrivacyB6;
            document.querySelector(".privacy_modalGroup .privacy_B7").textContent = text.PrivacyB7;
            document.querySelector(".privacy_modalGroup .privacy_B8").textContent = text.PrivacyB8;
            document.querySelector(".privacy_modalGroup .privacy_B9").textContent = text.PrivacyB9;
            document.querySelector(".privacy_modalGroup .privacy_B10").textContent = text.PrivacyB10;
            document.querySelector(".privacy_modalGroup .privacy_B11").textContent = text.PrivacyB11;
            document.querySelector(".privacy_modalGroup .privacy_B12").textContent = text.PrivacyB12;
            document.querySelector(".privacy_modalGroup .privacy_B13").textContent = text.PrivacyB13;
            document.querySelector(".privacy_modalGroup .privacy_B14").textContent = text.PrivacyB14;
            document.querySelector(".privacy_modalGroup .privacy_B15").textContent = text.PrivacyB15;


            // 제 3조 (C)
            document.querySelector(".privacy_modalGroup .privacyTitleC").textContent = text.PrivacyTitleC;
            document.querySelector(".privacy_modalGroup .privacy_C1").textContent = text.PrivacyC1;
            document.querySelector(".privacy_modalGroup .privacy_C2").textContent = text.PrivacyC2;
            document.querySelector(".privacy_modalGroup .privacy_C3").textContent = text.PrivacyC3;
            document.querySelector(".privacy_modalGroup .privacy_C4").textContent = text.PrivacyC4;


            // 제 4조 (D)
            document.querySelector(".privacy_modalGroup .privacyTitleD").textContent = text.PrivacyTitleD;
            document.querySelector(".privacy_modalGroup .privacy_D1").textContent = text.PrivacyD1;
            document.querySelector(".privacy_modalGroup .privacy_D2").textContent = text.PrivacyD2;
            document.querySelector(".privacy_modalGroup .privacy_D3").textContent = text.PrivacyD3;
            document.querySelector(".privacy_modalGroup .privacy_D4").textContent = text.PrivacyD4;
            document.querySelector(".privacy_modalGroup .privacy_D5").textContent = text.PrivacyD5;
            document.querySelector(".privacy_modalGroup .privacy_D6").textContent = text.PrivacyD6;
            document.querySelector(".privacy_modalGroup .privacy_D7").textContent = text.PrivacyD7;
            document.querySelector(".privacy_modalGroup .privacy_D8").textContent = text.PrivacyD8;
            document.querySelector(".privacy_modalGroup .privacy_D9").textContent = text.PrivacyD9;


            // 제 5조 (E)
            document.querySelector(".privacy_modalGroup .privacyTitleE").textContent = text.PrivacyTitleE;
            document.querySelector(".privacy_modalGroup .privacy_E1").textContent = text.PrivacyE1;
            document.querySelector(".privacy_modalGroup .privacy_E2").textContent = text.PrivacyE2;
            document.querySelector(".privacy_modalGroup .privacy_E3").textContent = text.PrivacyE3;
            document.querySelector(".privacy_modalGroup .privacy_E4").textContent = text.PrivacyE4;
            document.querySelector(".privacy_modalGroup .privacy_E5").textContent = text.PrivacyE5;
            document.querySelector(".privacy_modalGroup .privacy_E6").textContent = text.PrivacyE6;
            document.querySelector(".privacy_modalGroup .privacy_E7").textContent = text.PrivacyE7;
            document.querySelector(".privacy_modalGroup .privacy_E8").textContent = text.PrivacyE8;


            // 제 6조 (F)
            document.querySelector(".privacy_modalGroup .privacyTitleF").textContent = text.PrivacyTitleF;
            document.querySelector(".privacy_modalGroup .privacy_F1").textContent = text.PrivacyF1;
            document.querySelector(".privacy_modalGroup .privacy_F2").textContent = text.PrivacyF2;
            document.querySelector(".privacy_modalGroup .privacy_F3").textContent = text.PrivacyF3;
            document.querySelector(".privacy_modalGroup .privacy_F4").textContent = text.PrivacyF4;
            document.querySelector(".privacy_modalGroup .privacy_F5").textContent = text.PrivacyF5;
            document.querySelector(".privacy_modalGroup .privacy_F6").textContent = text.PrivacyF6;
            document.querySelector(".privacy_modalGroup .privacy_F7").textContent = text.PrivacyF7;


            // 제 7조 (G)
            document.querySelector(".privacy_modalGroup .privacyTitleG").textContent = text.PrivacyTitleG;
            document.querySelector(".privacy_modalGroup .privacy_G1").textContent = text.PrivacyG1;
            document.querySelector(".privacy_modalGroup .privacy_G2").textContent = text.PrivacyG2;
            document.querySelector(".privacy_modalGroup .privacy_G3").textContent = text.PrivacyG3;
            document.querySelector(".privacy_modalGroup .privacy_G4").textContent = text.PrivacyG4;


            // 제 8조 (H)
            document.querySelector(".privacy_modalGroup .privacyTitleH").textContent = text.PrivacyTitleH;
            document.querySelector(".privacy_modalGroup .privacy_H1").textContent = text.PrivacyH1;
            document.querySelector(".privacy_modalGroup .privacy_H2").textContent = text.PrivacyH2;
            document.querySelector(".privacy_modalGroup .privacy_H3").textContent = text.PrivacyH3;
            document.querySelector(".privacy_modalGroup .privacy_H4").textContent = text.PrivacyH4;
            document.querySelector(".privacy_modalGroup .privacy_H5").textContent = text.PrivacyH5;


            // 제 9조 (I)
            document.querySelector(".privacy_modalGroup .privacyTitleI").textContent = text.PrivacyTitleI;
            document.querySelector(".privacy_modalGroup .privacy_I1").textContent = text.PrivacyI1;
            document.querySelector(".privacy_modalGroup .privacy_I2").textContent = text.PrivacyI2;
            document.querySelector(".privacy_modalGroup .privacy_I3").textContent = text.PrivacyI3;
            document.querySelector(".privacy_modalGroup .privacy_I4").textContent = text.PrivacyI4;
            document.querySelector(".privacy_modalGroup .privacy_I5").textContent = text.PrivacyI5;
            document.querySelector(".privacy_modalGroup .privacy_I6").textContent = text.PrivacyI6;


            // 제 10조 (J)
            document.querySelector(".privacy_modalGroup .privacyTitleJ").textContent = text.PrivacyTitleJ;
            document.querySelector(".privacy_modalGroup .privacy_J1").textContent = text.PrivacyJ1;
            document.querySelector(".privacy_modalGroup .privacy_J2").textContent = text.PrivacyJ2;
            document.querySelector(".privacy_modalGroup .privacy_J3").textContent = text.PrivacyJ3;
            document.querySelector(".privacy_modalGroup .privacy_J4").textContent = text.PrivacyJ4;


            // 제 11조 (K)
            document.querySelector(".privacy_modalGroup .privacyTitleK").textContent = text.PrivacyTitleK;
            document.querySelector(".privacy_modalGroup .privacy_K1").textContent = text.PrivacyK1;
            document.querySelector(".privacy_modalGroup .privacy_K2").textContent = text.PrivacyK2;
            document.querySelector(".privacy_modalGroup .privacy_K3").textContent = text.PrivacyK3;
            document.querySelector(".privacy_modalGroup .privacy_K4").textContent = text.PrivacyK4;
            document.querySelector(".privacy_modalGroup .privacy_K5").textContent = text.PrivacyK5;
            document.querySelector(".privacy_modalGroup .privacy_K6").textContent = text.PrivacyK6;
            document.querySelector(".privacy_modalGroup .privacy_K7").textContent = text.PrivacyK7;
            document.querySelector(".privacy_modalGroup .privacy_K8").textContent = text.PrivacyK8;
            document.querySelector(".privacy_modalGroup .privacy_K9").textContent = text.PrivacyK9;
            document.querySelector(".privacy_modalGroup .privacy_K10").textContent = text.PrivacyK10;
            document.querySelector(".privacy_modalGroup .privacy_K11").textContent = text.PrivacyK11;
            document.querySelector(".privacy_modalGroup .privacy_K12").textContent = text.PrivacyK12;
            document.querySelector(".privacy_modalGroup .privacy_K13").textContent = text.PrivacyK13;

            // 시행기간
            document.querySelector(".privacy_modalGroup .privacyTitleL").textContent = text.PrivacyTitleL;
            document.querySelector(".privacy_modalGroup .privacy_date").textContent = text.PrivacyDate;
        }
        getModalPrivacy();
    } else {
        PrivacyModalText.style.display="none";
    }
}
if(PrivacyModalText){
    window.addEventListener('load', privacyModalTextJS);
    window.addEventListener('hashchange', privacyModalTextJS);
}