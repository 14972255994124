// <!-- 문의업로드 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 문의업로드 언어번역 ]
function getInquiryUpload(){
  const InquiryUploadTitle = document.querySelector(".inquiryUpload_title");
  const InquiryUploadDescription = document.querySelector(".inquiryUpload_description");
  const InquiryUploadSubmit = document.querySelector(".inquiryUpload_submit");

  const ChanegPasswordText = {
    en: {  // 영어
      InquiryUploadTitle : "inquiry",
      InquiryUploadDescription: "description",
      InquiryUploadSubmit:"Confirm"
    },
    ko: {  // 한국어
      InquiryUploadTitle : "문의하기",
      InquiryUploadDescription: "설명",
      InquiryUploadSubmit:"확인"
    }
  }
  const Language = navigator.language.split("-")[0];
  const Text = ChanegPasswordText[Language] || ChanegPasswordText.en;

  if (InquiryUploadTitle !== null) InquiryUploadTitle.textContent = Text.InquiryUploadTitle;
  if (InquiryUploadDescription !== null) InquiryUploadDescription.placeholder = Text.InquiryUploadDescription;
  if (InquiryUploadSubmit !== null) InquiryUploadSubmit.textContent = Text.InquiryUploadSubmit;
}
if(UserID){
  getInquiryUpload();
}




//// [ 문의업로드 해쉬/로드 ]
function inquiryUploadJS(){
  const InquiryUpload = document.querySelector(".inquiryUpload");
  const InquiryUploadDescription = document.querySelector(".inquiryUpload_description");
  InquiryUploadDescription.value = "";
  if(window.location.hash === `#inquiryupload/${UserID.innerText}`){
    InquiryUpload.style.display="flex";
  } else {
    InquiryUpload.style.display="none";
  }
}
if(UserID){
  window.addEventListener('hashchange', inquiryUploadJS);
  window.addEventListener('load', inquiryUploadJS);
}





//// [ 문의 제출 ]
function inquiryUploadSubmit(){
  const InquiryUploadSubmit = document.querySelector(".inquiryUpload_submit");
  const InquiryUploadDescription = document.querySelector(".inquiryUpload_description");
  const MeHeader = document.querySelector(".me_header");
  InquiryUploadSubmit.addEventListener('click', function() {
    const InquiryDescriptions = InquiryUploadDescription.value;
    const getCurrentDateString = () => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // getMonth()는 0부터 시작하므로 1을 더해줍니다.
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    const InquiryDates = getCurrentDateString();
    if(InquiryUploadDescription.value === ""){
      alert("입력 하여주세요.");
    }else{
      //alert("문의 제출이 완료 되었습니다.");
      MeHeader.click();
      fetch('/inquiry/inquiryupload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ InquiryDescription:InquiryDescriptions,  InquiryDate:InquiryDates })
      });
    }
  });
}
if(UserID){
  inquiryUploadSubmit();
}