// <!-- 유저디테일 JAVASCRIPT -->
const UserID = document.querySelector(".userID");




//// [ 유저디테일 보이기/숨기기 ]
function userDetailJS() {
  const Hash = window.location.hash;
  const UserDetailBg = document.querySelector(".userDetail-bg");
  UserDetailBg.classList.add("hiddens");
  if (Hash.includes('#users')) {
    UserDetailBg.classList.remove("hiddens");
  }else{
    UserDetailBg.classList.add("hiddens");
  }
}
if (UserID) {
  window.addEventListener("load", userDetailJS);
  window.addEventListener("hashchange", userDetailJS);
}




//// 아이콘 버튼 클릭 시
function userDetailIcon() {
  const UserSaveBtn = document.querySelector(".userInfoB_userSaveBtn");
  const UserVideoBtn = document.querySelector(".userInfoB_userVideoBtn");
  const UserSaveBtnIcon = document.querySelector(".userInfoB_userSaveBtn i");
  const UserVideoBtnIcon = document.querySelector(".userInfoB_userVideoBtn i");
  const UvvideoBlockBg = document.querySelector(".uVvideoBlock-bg");
  const UsvideoBlockBg = document.querySelector(".uSvideoBlock-bg");
  UvvideoBlockBg.classList.add("hiddens");
  UserSaveBtn.addEventListener('click', function() {
    UserSaveBtnIcon.style ="color:black; border-bottom:1px solid black;";
    UserVideoBtnIcon.style ="color:rgba(85, 85, 85, 0.588); border-bottom:0px solid black;";
    UsvideoBlockBg.classList.remove("hiddens");
    UvvideoBlockBg.classList.add("hiddens");
  });
  UserVideoBtn.addEventListener('click', function() {
    UserSaveBtnIcon.style ="color:rgba(85, 85, 85, 0.588); border-bottom:0px solid black;"; 
    UserVideoBtnIcon.style ="color:black; border-bottom:1px solid black;";
    UsvideoBlockBg.classList.add("hiddens");
    UvvideoBlockBg.classList.remove("hiddens");
  });
}
if (UserID) {
  window.addEventListener('load', userDetailIcon);
}