// <!-- 회원탈퇴 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// { 회원탈퇴 언어번역 }
function getWithdrawal(){
  const WithdrawalTitle = document.querySelector(".withdrawal_title"); // 회원탈퇴 (제목)
  const WithdrawalEmail = document.querySelector(".withdrawal_email"); // 이메일
  const WithdrawalPassword = document.querySelector(".withdrawal_password"); // 비밀번호
  const WithdrawalSubmit = document.querySelector(".withdrawal_submit"); // 회원탈퇴 (버튼)
  const WithdrawalText = {
    en: { // 영어
      WithdrawalTitle: "Withdrawal",
      WithdrawalEmail: "Email",
      WithdrawalPassword: "Password",
      WithdrawalSubmit: "Confirm"
    },
    ko: { // 한국어
      WithdrawalTitle: "계정탈퇴",
      WithdrawalEmail: "이메일",
      WithdrawalPassword: "비밀번호",
      WithdrawalSubmit: "확인"
    }
  }
  const Language = navigator.language.split("-")[0];
  const Text = WithdrawalText[Language] || WithdrawalText.en;

  if (WithdrawalTitle !== null) WithdrawalTitle.textContent = Text.WithdrawalTitle;
  if (WithdrawalEmail !== null) WithdrawalEmail.placeholder = Text.WithdrawalEmail;
  if (WithdrawalPassword !== null) WithdrawalPassword.placeholder = Text.WithdrawalPassword;
  if (WithdrawalSubmit !== null) WithdrawalSubmit.textContent = Text.WithdrawalSubmit;
}
if(UserID){
  getWithdrawal();
}






//// [ 회원탈퇴 해쉬/로드 ]
function withdrawalJS() {
  const Withdrawal = document.querySelector(".withdrawal");
  const WithdrawalPassword = document.querySelector(".withdrawal_password");
  const UserId = UserID.innerText;
  WithdrawalPassword.value = "" ;
  if(window.location.hash === `#withdrawal/${UserId}`){
    Withdrawal.style.display="flex"; // 회원탈퇴 페이지를 보여주자!
  }else{
    Withdrawal.style.display="none";
  }
}
if(UserID){
  window.addEventListener('hashchange', withdrawalJS);
  window.addEventListener('load', withdrawalJS);
}







//// [ 회원탈퇴 제출 ]
function withdrawalSubmit(){
  const WithdrawalEmail = document.querySelector(".withdrawal_email");
  const WithdrawalPassword = document.querySelector(".withdrawal_password");
  const WithdrawalSubmit = document.querySelector(".withdrawal_submit");
  const Confirm = document.getElementById('confirm');
  function withdrawalFetch() {
    if ( WithdrawalPassword.value === "") {
      alert("비밀번호를 입력해주세요.");
    } else {
      //alert("회원탈퇴 완료되었습니다.");
      WithdrawalSubmit.innerHTML = '<div class="loader"></div>';
      Confirm.innerHTML = '<div class="loader"></div>';
      const withdrawalData = {
          email: WithdrawalEmail.value,
          password: WithdrawalPassword.value
      };
      fetch('/users/withdrawal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(withdrawalData)
      })
      .then(response => response.json())
      .then(data => {
        if (data.error) { 
          WithdrawalSubmit.innerHTML = '확인';
          Confirm.innerHTML = '<i class="bi bi-check-lg"></i>';
          alert(data.error); // 에러 메시지 처리
        }else {
          window.location.href = '/';
        }
      });
    }
  }
  WithdrawalSubmit.addEventListener("click", withdrawalFetch);
}
if(UserID){
  withdrawalSubmit();
}