// <!-- 비디오 플레이어 JAVASCRIPT -->
const UserID = document.querySelector(".userID");

//// [ 비디오 플레이어 ]
let Video, ProgressBar, ProgressFilled, VideoPlayPause, VideoPlayPauseIcon, FullScreen, FullScreenIcon, ScreenLock, ScreenLockIcon ;
let IsDragging = false;
let IconTimeout;


// 비디오 컨트롤 초기화 함수
function newVideoControls(videoContainer) {
  Video = videoContainer.querySelector('video');
  ProgressBar = videoContainer.querySelector('.progress-bar');
  ProgressFilled = videoContainer.querySelector('.progress-filled');
  VideoPlayPause = videoContainer.querySelector('.video-play-pause');
  VideoPlayPauseIcon = VideoPlayPause.querySelector('i');
  FullScreen = videoContainer.querySelector('.video-fullScreen');
  FullScreenIcon = videoContainer.querySelector('.video-fullScreen i');
  ScreenLock = videoContainer.querySelector('.video-screenLock');
  ScreenLockIcon = videoContainer.querySelector('.video-screenLock i');

  // 이벤트 리스너 설정
  addEventListeners();
}


// FullScreen 클릭 핸들러
function handleFullScreenClick(e) {
  if (e.target !== FullScreenIcon) togglePlayPause();
}

// ScreenLock 클릭 핸들러
function handleScreenLockClick(e) {
  if (e.target !== ScreenLockIcon) togglePlayPause();
}


// 이벤트 리스너 추가 함수
function addEventListeners() {
  VideoPlayPause.addEventListener('click', togglePlayPause);
  Video.addEventListener('timeupdate', updateProgressBar);
  ProgressBar.addEventListener('click', seekVideo);
  ProgressBar.addEventListener('touchstart', startDrag);
  ProgressBar.addEventListener('touchmove', dragProgressBar);
  document.addEventListener('touchend', stopDrag);
  FullScreen.addEventListener('click', handleFullScreenClick);
  ScreenLock.addEventListener('click', handleScreenLockClick);
}

// 이벤트 리스너 제거 함수
function clearEventListeners() {
  VideoPlayPause?.removeEventListener('click', togglePlayPause);
  Video?.removeEventListener('timeupdate', updateProgressBar);
  ProgressBar?.removeEventListener('click', seekVideo);
  ProgressBar?.removeEventListener('touchstart', startDrag);
  ProgressBar?.removeEventListener('touchmove', dragProgressBar);
  document.removeEventListener('touchend', stopDrag);
  FullScreen?.removeEventListener('click', handleFullScreenClick);
  ScreenLock?.removeEventListener('click', handleScreenLockClick);
}

// 비디오 재생 상태 변경
function togglePlayPause() {
  const isPaused = Video.paused;
  isPaused ? Video.play() : Video.pause();

  VideoPlayPauseIcon.classList.toggle('fa-play', !isPaused);
  VideoPlayPauseIcon.classList.toggle('fa-pause', isPaused);
  
  showIconTemporarily();
}

// 아이콘 일정 시간 후 숨기기
function showIconTemporarily() {
  VideoPlayPauseIcon.style.display = 'grid';
  clearTimeout(IconTimeout);
  IconTimeout = setTimeout(() => {
    VideoPlayPauseIcon.style.display = 'none';
  }, 700);
}


// 진행 바 업데이트
function updateProgressBar() {
  const percent = (Video.currentTime / Video.duration) * 100;
  ProgressFilled.style.width = `${percent}%`;
}

// 클릭한 위치로 비디오 이동
function seekVideo(e) {
  const position = e.offsetX / ProgressBar.offsetWidth;
  updateVideoTime(position);
}

// 비디오 시간 업데이트
function updateVideoTime(position) {
  Video.currentTime = position * Video.duration;
  ProgressFilled.style.width = `${position * 100}%`;
}

// 드래그 시작
function startDrag(e) {
  IsDragging = true;
  dragProgressBar(e);
}

// 드래그 중 비디오 진행 바 업데이트
function dragProgressBar(e) {
  if (!IsDragging) return;

  const { left, width } = ProgressBar.getBoundingClientRect();
  const offsetX = e.touches[0].clientX - left;
  const position = Math.max(0, Math.min(offsetX / width, 1));
  
  updateVideoTime(position);
}

// 드래그 종료
function stopDrag() {
  IsDragging = false;
}

// 비디오 플레이 설정
function videoPlay() {
  const hash = window.location.hash;
  if (hash.includes('#videodetail')) {
    const MainVideoContainer = document.querySelector('.mainVideo');
    if(MainVideoContainer.innerHTML !== ""){
      clearEventListeners(); // 기존 이벤트 제거
      newVideoControls(MainVideoContainer); // 새 비디오 초기화
    }
  }
}

if(UserID) {
  window.addEventListener('hashchange', videoPlay);
  window.addEventListener('load', videoPlay);
}









//// [ 비디오 전체화면 ]
function fullScreen() {
  const MobileBottom = document.querySelector('.mobileBottom');
  const BodyContent = document.querySelector('.bodyContent');
  const MainVideo = document.querySelector('.mainVideo');
  const ThumbnailBg = MainVideo.querySelector('.thumbnail-bg');
  const FullScreenIcon = MainVideo.querySelector('.video-fullScreen i');
  const NavigationBarWhite = document.querySelector('.navigationBarWhite');
  const NavigationBarBlack = document.querySelector('.navigationBarBlack');
  const MobileBottomHome = document.querySelector('.mobileBottom-home');
  const MobileBottomSearch = document.querySelector('.mobileBottom-search');
  const MobileBottomUser = document.querySelector('.mobileBottom-user');
  let width = window.innerWidth;
  let isFullScreen = false;

  
  // 브라우저 리사이즈 시 원상태 복구
  if (width > 540) {
    ThumbnailBg.style.height = `500px`;
    FullScreenIcon.classList.remove('bi-fullscreen-exit');
    FullScreenIcon.classList.add('bi-fullscreen');
  } else {
    ThumbnailBg.style.height = `47vh`;
    FullScreenIcon.classList.remove('bi-fullscreen-exit');
    FullScreenIcon.classList.add('bi-fullscreen');
  }

  // 스크롤 상태에 따른 MobileBottom 배경 변경
  function backgroundScroll() {
    if (ThumbnailBg.style.height === '47vh') {
      return;
    }

    if (BodyContent.scrollTop > 0) {
      MobileBottom.style.background = "white";
      MobileBottomHome.style="color:rgb(141, 141, 141); background-color:rgb(243, 243, 243);";
      MobileBottomSearch.style="color:rgb(141, 141, 141); background-color:rgb(243, 243, 243);";
      MobileBottomUser.style= "color:black; background-color:rgb(243, 243, 243);";
      NavigationBarWhite.click();
    } else {
      MobileBottom.style.background = "black";
      MobileBottomHome.style="color:white; background-color:rgba(0, 0, 0, 0);";
      MobileBottomSearch.style="color:white; background-color:rgba(0, 0, 0, 0);";
      MobileBottomUser.style= "color:white; background-color:rgba(0, 0, 0, 0);";
      NavigationBarBlack.click();
    }
  }

  // 아이콘 클릭 시
  function screen() {
    if (isFullScreen) {
      ThumbnailBg.style.height = `47vh`;
      FullScreenIcon.classList.remove('bi-fullscreen-exit');
      FullScreenIcon.classList.add('bi-fullscreen');
      MobileBottom.style.background = "white";
      MobileBottomHome.style="color:rgb(141, 141, 141); background-color:rgb(243, 243, 243);";
      MobileBottomSearch.style="color:rgb(141, 141, 141); background-color:rgb(243, 243, 243);";
      MobileBottomUser.style= "color:black; background-color:rgb(243, 243, 243);";
      NavigationBarWhite.click();
      BodyContent.removeEventListener('scroll', backgroundScroll);
    } else {
      ThumbnailBg.style.height = `calc(100vh - 57px)`;
      FullScreenIcon.classList.remove('bi-fullscreen');
      FullScreenIcon.classList.add('bi-fullscreen-exit');
      MobileBottomHome.style="color:white; background-color:rgba(0, 0, 0, 0);";
      MobileBottomSearch.style="color:white; background-color:rgba(0, 0, 0, 0);";
      MobileBottomUser.style= "color:white; background-color:rgba(0, 0, 0, 0);";
      backgroundScroll();
      BodyContent.addEventListener('scroll', backgroundScroll);
    }
    isFullScreen = !isFullScreen;
  }
  FullScreenIcon.removeEventListener('click', screen);
  FullScreenIcon.addEventListener('click', screen);

  // 해시 체인지 시 초기화
  window.addEventListener('hashchange', () => {
    NavigationBarWhite.click();
    BodyContent.removeEventListener('scroll', backgroundScroll);
    MobileBottom.style.background = "white";
  });
}
if (UserID) {
  window.addEventListener('hashchange', fullScreen);
  window.addEventListener('load', fullScreen);
  window.addEventListener('resize', fullScreen);
}







//// [ 비디오 잠금화면 ]
function screenLock() {
  const BodyContent = document.querySelector('.bodyContent');
  const ScreenLockModal = document.querySelector('.screenLockModal');
  const MainVideo = document.querySelector('.mainVideo');
  const ScreenLockIcon = MainVideo.querySelector('.video-screenLock i');
  let isLocked = false;

  function toggleScreenLock() {
    if (isLocked) {
      ScreenLockModal.style.display = "none";
      BodyContent.style.pointerEvents = 'auto';
      ScreenLockIcon.style.pointerEvents = 'auto'; // 아이콘은 항상 상호작용 가능
      ScreenLockIcon.classList.remove('fa-lock'); // 잠금 아이콘 제거
      ScreenLockIcon.classList.add('fa-unlock'); // 잠금 해제 아이콘 추가
    } else {
      ScreenLockModal.style.display = "grid";
      BodyContent.style.pointerEvents = 'none';
      ScreenLockIcon.style.pointerEvents = 'auto'; // 아이콘은 항상 상호작용 가능
      ScreenLockIcon.classList.remove('fa-unlock'); // 잠금 해제 아이콘 제거
      ScreenLockIcon.classList.add('fa-lock'); // 잠금 아이콘 추가
      setTimeout(() => {
        ScreenLockModal.style.display = "none";
      }, 1000);
    }
    isLocked = !isLocked; // 상태 반전
  }

  function handleOutsideClick(event) {
    if (isLocked && event.target !== ScreenLockIcon) {
      ScreenLockModal.style.display = "grid";
      setTimeout(() => {
        ScreenLockModal.style.display = "none";
      }, 1000);
    }
  }

  ScreenLockIcon.removeEventListener('click', toggleScreenLock);
  ScreenLockIcon.addEventListener('click', toggleScreenLock);

  document.removeEventListener('click', handleOutsideClick);
  document.addEventListener('click', handleOutsideClick);
}
if (UserID) {
  window.addEventListener('hashchange', screenLock);
  window.addEventListener('load', screenLock);
}