// <!-- 비디오디테일 JAVASCRIPT -->
const UserID = document.querySelector(".userID");
let MyString ;




//// [ 비디오디테일 보이기,숨기기 ]
function videoDetailJS() {
  const VideoDetail = document.querySelector('.videoDetail');
  const Hash = window.location.hash;
  if(Hash.includes('#videodetail')){
    VideoDetail.style.display = 'flex';
  }else{
    VideoDetail.style.display = 'none';
  }
}
if(UserID){
  window.addEventListener('load', videoDetailJS);
  window.addEventListener('hashchange', videoDetailJS);
}









//// [ 비디오디테일 - 메인비디오 ]
// 참고: 제자리로 갈때 홈 Block 부분만 다르게 했다. 터치로딩으로 순서가 계속 바뀌기 때문이다.
let IndexNumber = 0;
function mainVideo() {
  const Hash = window.location.hash;
  const MainVideo = document.querySelector('.mainVideo');
  const MainVideoPlay = MainVideo.querySelector('video');
  if(MainVideoPlay){
    MainVideoPlay.muted = true;
  }
  
  // MainVideo 제자리 이동
  const MainVideoBlock = MainVideo.querySelector('.Block');
  const MainUsVideoBlock = MainVideo.querySelector('.uS-Block');
  const MainUvVideoBlock = MainVideo.querySelector('.uV-Block');

  if(MainVideoBlock){
    const Video = MainVideoBlock.querySelector('video');
    const VideoDescription = MainVideoBlock.querySelector('.videoDescription');
    const ThumbnailBg = MainVideoBlock.querySelector('.thumbnail-bg');
    const VideoBlock = document.querySelector('.videoBlock');
    Video.currentTime = 0;
    Video.pause();
    ThumbnailBg.style.height = "auto";
    if(VideoDescription){
      VideoDescription.textContent = MyString;
    }
    VideoBlock.insertBefore(MainVideoBlock, VideoBlock.children[IndexNumber] || null);
  }else if(MainUsVideoBlock){
    const Video = MainUsVideoBlock.querySelector('video');
    const VideoDescription = MainUsVideoBlock.querySelector('.videoDescription');
    const ThumbnailBg = MainUsVideoBlock.querySelector('.thumbnail-bg');
    const UsIndexNumber = MainUsVideoBlock.querySelector('.block-number').textContent;
    const UsVideoBlock = document.querySelector('.uSvideoBlock');
    Video.currentTime = 0;
    Video.pause();
    ThumbnailBg.style.height = "auto";
    if(VideoDescription){
      VideoDescription.textContent = MyString;
    }
    UsVideoBlock.insertBefore(MainUsVideoBlock, UsVideoBlock.children[UsIndexNumber] || null);
  }else if(MainUvVideoBlock){
    const Video = MainUvVideoBlock.querySelector('video');
    const VideoDescription = MainUvVideoBlock.querySelector('.videoDescription');
    const ThumbnailBg = MainUvVideoBlock.querySelector('.thumbnail-bg');
    const UvIndexNumber = MainUvVideoBlock.querySelector('.block-number').textContent;
    const UvVideoBlock = document.querySelector('.uVvideoBlock');
    Video.currentTime = 0;
    Video.pause();
    ThumbnailBg.style.height = "auto";
    if(VideoDescription){
      VideoDescription.textContent = MyString;
    }
    UvVideoBlock.insertBefore(MainUvVideoBlock, UvVideoBlock.children[UvIndexNumber] || null);
  }
  
  // MainVideo에 appendChild
  MainVideo.innerHTML = ''; // 비우기
  if (Hash.includes('#videodetail')) {
    const HlsVideoUrl = Hash.split('?')[1];
    
    const updateVideoBlock = (block) => {
      const Video = block.querySelector('video');
      Video.currentTime = 0;
      Video.play();
      MainVideo.appendChild(block);
      MutedBtn.click();
    };

    if(Hash.includes('uS/poniVideo')){
      const UsBlock = document.querySelector(`.uS-Block[data-videoUrl="${HlsVideoUrl}"]`);
      updateVideoBlock(UsBlock);
    }else if(Hash.includes('uV/poniVideo')){
      const UvBlock = document.querySelector(`.uV-Block[data-videoUrl="${HlsVideoUrl}"]`);
      updateVideoBlock(UvBlock);
    }else if(Hash.includes('sV/poniVideo')){
      const SvBlock = document.querySelector(`.sV-Block[data-videoUrl="${HlsVideoUrl}"]`);
      updateVideoBlock(SvBlock);
    }else{
      const Block = document.querySelector(`.Block[data-videoUrl="${HlsVideoUrl}"]`);
      const VideoBlock = document.querySelector('.videoBlock');
      const AllBlock = VideoBlock.querySelectorAll('.Block');
      if (AllBlock) {
        const ArrayNumber = Array.from(AllBlock).indexOf(Block);
        IndexNumber = ArrayNumber;
      }
      updateVideoBlock(Block);
    }
  }
}
if(UserID){
  window.addEventListener('hashchange', mainVideo);
}









//// [ 소리버튼 클릭 시 ]
const MutedBtn = document.querySelector('.mutedBtn');
function MutedBtnClick() {
  const MainVideo = document.querySelector('.mainVideo');
  const MainVideoPlayer = MainVideo.querySelector('video');
  if (MainVideoPlayer) {
    MainVideoPlayer.muted = false;
  }
}
if(UserID){
  MutedBtn.removeEventListener('click', MutedBtnClick);
  MutedBtn.addEventListener('click', MutedBtnClick);
}







//// [ 동일한 아이디를 가진 비디오 숨기기 ]
function MainVideoHide() {
  const Hash = window.location.hash;
  const Block = document.querySelectorAll('.Block.hidden');
  Block.forEach(item => item.classList.remove("hidden"));
  if (Hash.includes('#videodetail') && Hash.includes('uV/poniVideo') || Hash.includes('uS/poniVideo') || Hash.includes('sV/poniVideo')){
    const HlsVideoUrl = Hash.split('?')[1];
    const TargetBlock = document.querySelector(`.Block[data-videoUrl="${HlsVideoUrl}"]`);
    if (TargetBlock) {
      TargetBlock.classList.add("hidden");
    }
  }
}
function loadVideoHide() {
  const Hash = window.location.hash;
  const Block = document.querySelectorAll('.Block.hidden');
  Block.forEach(item => item.classList.remove("hidden"));
  if (Hash.includes('#videodetail') || Hash.includes('uV/poniVideo') || Hash.includes('uS/poniVideo') || Hash.includes('sV/poniVideo')){
    const HlsVideoUrl = Hash.split('?')[1];
    const TargetBlock = document.querySelector(`.Block[data-videoUrl="${HlsVideoUrl}"]`);
    if (TargetBlock) {
      TargetBlock.classList.add("hidden");
    }
  }
}
if (UserID) {
  window.addEventListener('hashchange', MainVideoHide);
  window.addEventListener('load', loadVideoHide);
}







//// [ 비디오설정 클릭 모달창 열기/닫기, 뒤로가기 클릭 시 모달창부터 닫기 ]
let VideoSettingIcon, VideoSettingModalBg, VideoSettingModal;
let isModalOpen = false;
function videoSetting() {
  const Hash = window.location.hash;
  if (Hash.includes('#videoEdit')){
    window.onpopstate = function() {
      window.onpopstate = null;
      history.back();
    };
  }
  if (!Hash.includes('#videodetail'))return;
  const MainVideo = document.querySelector('.mainVideo');
  VideoSettingIcon = MainVideo.querySelector('.videoSetting i');
  VideoSettingModalBg = MainVideo.querySelector('.videoSettingModalBg');
  VideoSettingModal = MainVideo.querySelector('.videoSettingModal');
  VideoSettingIcon.removeEventListener('click', openModal);
  VideoSettingIcon.addEventListener('click', openModal);
}
function pushStateNull() {
  isModalOpen = false;
  VideoSettingModalBg.style.display = "none";
  window.onpopstate = null;
}
function openModal() {
  if (isModalOpen) {
    isModalOpen = false;
    VideoSettingModalBg.style.display = "none";
    VideoSettingModalBg.removeEventListener('click', closeModal);
  } else {
    isModalOpen = true;
    VideoSettingModalBg.style.display = "block";
    VideoSettingModalBg.addEventListener('click', closeModal);
    history.pushState(null, "", location.href);
    window.onpopstate = pushStateNull;
  }
}
function closeModal(event) {
  if (event.target !== VideoSettingModal && !VideoSettingModal.contains(event.target)) {
    isModalOpen = false;
    VideoSettingModalBg.style.display = "none";
    window.onpopstate = null;
    history.back();
    VideoSettingModalBg.removeEventListener('click', closeModal);
  }
}
if (UserID) {
  window.addEventListener('hashchange', videoSetting);
  window.addEventListener('load', videoSetting);
}










//// [ 설명기능 부분 ]
// 참고: 로드 시에는 따로 주어야 한다. 데이터 가지고오는데 시간이 걸리기 때문이다.
let addLength = "<span class='descriptionAddText'>더보기</span>";
function videoDescription() {
  const Hash = window.location.hash;
  if (!Hash.includes('#videodetail')) return;

  const MainVideo = document.querySelector('.mainVideo');
  const VideoDescriptionBg = MainVideo.querySelector('.videoDescriptionBg');
  const VideoDescription = MainVideo.querySelector('.videoDescription');
  if(VideoDescriptionBg){
  
    // videoDescription 기능적용
    function truncateString(str) {
      if (str.length > 80) {
        return str.slice(0, 80) + `...${addLength}`;
      } else {
        return str;
      }
    }
  
    MyString = VideoDescription.textContent;
    const regURL = new RegExp("(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)", "gi");
    const MyStrings = `${MyString}`.replace(regURL, "<a href='$1://$2' target='_blank'>$1://$2</a>");
    VideoDescription.innerHTML = truncateString(MyStrings);
    let IsTruncated = true;
  
    // 클릭 이벤트 핸들러
    function toggleDescription() {
      if (IsTruncated) {
        VideoDescription.innerHTML = MyStrings;
        IsTruncated = false;
      } else {
        VideoDescription.innerHTML = truncateString(MyStrings);
        IsTruncated = true;
      }
    }
  
    VideoDescriptionBg.removeEventListener("click", toggleDescription);
    VideoDescriptionBg.addEventListener("click", toggleDescription);
  }
}
if (UserID) {
  window.addEventListener('hashchange', videoDescription);
}