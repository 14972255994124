// <!-- 모바일검색 JAVASCRIPT -->
const UserID = document.querySelector(".userID");



// [ 모바일검색 언어번역 ]
function MobileSearchLanguage(){
    const MobileSearchInput = document.getElementById("mobileSearchInput"); // 모바일 검색 Input
    const mobileSearchText = {
        en: { MobileSearchInput: " Search"}, // 영어
        es: { MobileSearchInput: " Buscar"}, // 스페인어
        zh: { MobileSearchInput: " 搜索"}, // 중국어
        ja: { MobileSearchInput: " 検索"}, // 일본어
        fr: { MobileSearchInput: " Rechercher"}, // 프랑스어
        de: { MobileSearchInput: " Suchen"}, // 독일어
        it: { MobileSearchInput: " Cerca"}, // 이탈리아어
        pt: { MobileSearchInput: " Pesquisar"}, // 포르투갈어
        ru: { MobileSearchInput: " Поиск"}, // 러시아어
        sv: { MobileSearchInput: " Sök"}, // 스웨덴어
        fa: { MobileSearchInput: " جستجو"}, // 페르시아어
        da: { MobileSearchInput: " Søg"}, // 덴마크어
        nb: { MobileSearchInput: " Søk"}, // 노르웨이어(보크말)
        nn: { MobileSearchInput: " Søk"}, // 노르웨이어(니노르스크)
        nl: { MobileSearchInput: " Zoeken"}, // 네덜란드어
        tr: { MobileSearchInput: " Ara"}, // 터키어
        is: { MobileSearchInput: " Leita"}, // 아이슬란드어
        fi: { MobileSearchInput: " Hae"}, // 핀란드어
        cs: { MobileSearchInput: " Hledat"}, // 체코어
        vi: { MobileSearchInput: " Tìm kiếm"}, // 베트남어
        th: { MobileSearchInput: " ค้นหา"}, // 태국어
        tl: { MobileSearchInput: " Maghanap"}, // 필리핀어(Tagalog)
        fil: { MobileSearchInput: " Maghanap"}, // 필리핀어(Filipino)
        hu: { MobileSearchInput: " Keresés"}, // 헝가리어
        id: { MobileSearchInput: " Cari"}, // 인도네시아어
        ms: { MobileSearchInput: " Cari"}, // 말레이시아어
        pl: { MobileSearchInput: " Szukaj"}, // 폴란드어
        sk: { MobileSearchInput: " Hľadať"}, // 슬로바키아어
        sl: { MobileSearchInput: " Iskanje"}, // 슬로베니아어
        kk: { MobileSearchInput: " Іздеу"}, // 카자흐어
        ky: { MobileSearchInput: " Издөө"}, // 키르기스어
        uz: { MobileSearchInput: " Qidiruv"}, // 우즈베크어
        kn: { MobileSearchInput: " ಹುಡುಕು"}, // 칸나다어
        tg: { MobileSearchInput: " Ҷустуҷӯ"}, // 타지키스어
        tk: { MobileSearchInput: " Gözleg"}, // 투르크메니어
        ml: { MobileSearchInput: " തിരയുക"}, // 말라얄람어
        ar: { MobileSearchInput: " بحث"}, // 아랍어
        hi: { MobileSearchInput: " खोजें"}, // 힌디어
        mr: { MobileSearchInput: " शोधा"}, // 마라티어
        pa: { MobileSearchInput: " ਖੋਜ"}, // 펀잡어
        ta: { MobileSearchInput: " தேடல்"}, // 타밀어
        te: { MobileSearchInput: " శోధన"}, // 텔루구어
        hr: { MobileSearchInput: " Pretraživanje"}, // 크로아티아어
        ro: { MobileSearchInput: " Căutare"}, // 루마니아어
        sr: { MobileSearchInput: " Претрага"}, // 세르비아어
        lv: { MobileSearchInput: " Meklēšana"}, // 라트비아어
        lt: { MobileSearchInput: " Paieška"}, // 리투아니아어
        he: { MobileSearchInput: " חיפוש"}, // 히브리어
        bg: { MobileSearchInput: " Търсене"}, // 불가리아어
        ba: { MobileSearchInput: " Эҙләү"}, // 바슈키르어
        cv: { MobileSearchInput: " Шырамалли"}, // 츠바시어
        tt: { MobileSearchInput: " Эзләү"}, // 타타르어
        bn: { MobileSearchInput: " অনুসন্ধান"}, // 방글라어
        et: { MobileSearchInput: " Otsing"}, // 에스토니아어
        el: { MobileSearchInput: " Αναζήτηση"}, // 그리스어
        ca: { MobileSearchInput: " Cerca"}, // 카탈로니아어
        ko: { MobileSearchInput: " 검색"} // 한국어
    }
    const language = navigator.language.split("-")[0];
    const text = mobileSearchText[language] || mobileSearchText.en;
    if (MobileSearchInput !== null) MobileSearchInput.placeholder = text.MobileSearchInput;
}
if(UserID){
    MobileSearchLanguage();
}









//// [ 모바일검색 해쉬/로드 ]
function mobileSearchJS(){
    const MobileSearch = document.querySelector(".mobileSearch"); // 모바일 검색
    const MobileSearchInput = document.getElementById("mobileSearchInput"); // 모바일 검색 Input
    const MobileHistoryList = document.getElementById("mobileHistoryList"); // 모바일 저장된 검색어 리스트
    const MobileHistoryClose = document.querySelector(".mobileHistoryClose"); // 모바일 저장된 검색어 닫기
    if(window.location.hash === "#mobilesearch"){
        MobileHistoryList.style.display = "none";
        MobileHistoryList.innerHTML = "";
        MobileHistoryClose.style.display = "none";
        MobileSearchInput.value = "" ;

        MobileSearch.style.display="block";
    }else{
        MobileSearch.style.display="none";
    }
}
if(UserID){
    window.addEventListener('hashchange', mobileSearchJS);
    window.addEventListener('load', mobileSearchJS);
}







//// [ 모바일검색 기능 ]
function mobileSearch(){
    const MobileSearchClick = document.getElementById('mobileSearchClick');
    const MobileSearchInput = document.getElementById("mobileSearchInput"); // 모바일 검색 Input
    const MobileHistoryList = document.getElementById("mobileHistoryList"); // 모바일 저장된 검색어 리스트
    const MobileHistoryClose = document.querySelector(".mobileHistoryClose"); // 모바일 저장된 검색어 닫기
    
    // 검색어 로컬스토리지 저장
    function MobileSaveSearch() {
        const MobileSearchValue = MobileSearchInput.value.toLowerCase(); // 입력값을 소문자로 변환
        if (MobileSearchValue !== "") {
            const currentDate = new Date();
            const expiryDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000); // 현재 시간에서 14일 뒤의 시간
            // 로컬 스토리지에서 검색어 확인
            if (localStorage.getItem(MobileSearchValue)) {
                // 검색어가 이미 존재하면, 만료일만 업데이트
                localStorage.setItem(MobileSearchValue, expiryDate);
            } else {
                // 새로운 검색어 저장
                localStorage.setItem(MobileSearchValue, expiryDate);
                UpdateMobileSearchHistory();
            }
        }
    }
    
    
    // 저장된 검색어 보여주기 / 삭제 / 클릭 시 검색되게 하기
    function UpdateMobileSearchHistory() {
        MobileHistoryList.innerHTML = "";
        for (let i = 0; i < localStorage.length; i+=1) {
            const key = localStorage.key(i);
            const listItems = document.createElement("li");
            if (key.includes('@tosspayments') || key.includes('@payment-widget')){
                //
            } else {
                const value = new Date(localStorage.getItem(key));
                const currentDate = new Date();
                if (currentDate > value) {
                    localStorage.removeItem(key);
                }else{
                    listItems.innerHTML = key;
                    const deleteButton = document.createElement("button");
                    deleteButton.innerHTML = "X";
                    deleteButton.classList.add("deleteTermButton");
                    deleteButton.addEventListener("click",(event)=>{
                        event.stopPropagation();
                        localStorage.removeItem(key);
                        UpdateMobileSearchHistory();
                    })
                    listItems.appendChild(deleteButton);
                    MobileHistoryList.appendChild(listItems);
                }
            }
            listItems.addEventListener("click",()=>{
                MobileSearchInput.value = key;
                MobileSearchClick.href = `#search/${key}`;
                MobileSearchClick.click();
            })
        }
    }
    
    
    // 검색어를 a 태그 주소로 지정하고 클릭하기(주소로 이동)
    MobileSearchInput.addEventListener('keyup', function(event) {
        if (event.key === 'Enter') {
            const MobileSearchTerm = MobileSearchInput.value;
            MobileSearchClick.href = `#search/${MobileSearchTerm}`;
            MobileSearchClick.click();
            MobileSaveSearch();
        }
    });
    
    
    // 검색창 클릭 시
    MobileSearchInput.addEventListener("click", function () {
        MobileHistoryList.style.display = "block";
        UpdateMobileSearchHistory();
        MobileHistoryClose.style.display = "grid";
    });
    
    
    
    // 모바일 히스토리 닫기 클릭 시
    MobileHistoryClose.addEventListener("click", function () {
        MobileHistoryClose.style.display = "none";
        MobileHistoryList.innerHTML = "";
        MobileSearchInput.value = "" ;
        setTimeout(() => {
            MobileHistoryList.style.display = "none";
        }, 300); // 지연을 주는 이유는 키보드가 사라지고 나서 MobileBottom 이 보이게 하려고.
    });
}
if(UserID){
    mobileSearch();
}