// <!-- 모바일 Header JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 모바일 header ]
function mobileHeaderJS(){
  const MobileHeader = document.querySelector('.mobileHeader');
  const Hash = window.location.hash;

  // 표시할 해시 값들을 배열로 설정
  const validHashes = ['#home', '#search'];
  
  if (window.innerWidth > 1000) {
    // 화면 크기가 1000px 초과일 경우 무조건 안보이게
    MobileHeader.style = 'display:none';
  } else if (!Hash || validHashes.some(validHash => Hash.includes(validHash))) {
    MobileHeader.style = 'display:flex;';
  } else {
    MobileHeader.style = 'display:none';
  }
}
if(UserID){
  window.addEventListener('hashchange', mobileHeaderJS);
  window.addEventListener('load', mobileHeaderJS);
  window.addEventListener('resize', mobileHeaderJS);
}