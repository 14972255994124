// <!-- 프로필수정 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 프로필 편집 언어번역 ]
function getEditProfile(){
  const EditChangePasswordBtn = document.querySelector(".edit_changePasswordBtn"); // 비밀번호 변경
  const EditInquiryUploadBtn = document.querySelector(".edit_inquiryuploadBtn"); // 신고 및 문의
  const EditWithdrawalBtn = document.querySelector(".edit_withdrawalBtn"); // 회원탈퇴
  const EditProfileSubmit = document.querySelector(".editProfile_submit"); // 확인 (버튼)
  const EditProfileLogout = document.querySelector(".editProfile_logout"); // 모바일 로그아웃

  const EditProfileText = {
    en: { // 영어
      EditChangePasswordBtn: "Change Password",
      EditInquiryUploadBtn: "Inquiry",
      EditWithdrawalBtn: "Withdrawal",
      EditProfileSubmit: "Confirm",
      EditProfileLogout: "Logout"
    },
    ko: { // 한국어
      EditChangePasswordBtn: "비밀번호 변경",
      EditInquiryUploadBtn: "문의하기",
      EditWithdrawalBtn: "계정탈퇴",
      EditProfileSubmit: "확인",
      EditProfileLogout: "로그아웃"
    }
  }
  const Language = navigator.language.split("-")[0];
  const Text = EditProfileText[Language] || EditProfileText.en;

  if (EditChangePasswordBtn !== null) EditChangePasswordBtn.textContent = Text.EditChangePasswordBtn;
  if (EditInquiryUploadBtn !== null) EditInquiryUploadBtn.textContent = Text.EditInquiryUploadBtn;
  if (EditWithdrawalBtn !== null) EditWithdrawalBtn.textContent = Text.EditWithdrawalBtn;
  if (EditProfileSubmit !== null) EditProfileSubmit.textContent = Text.EditProfileSubmit;
  if (EditProfileLogout!== null) EditProfileLogout.textContent = Text.EditProfileLogout;
}
if(UserID){
  getEditProfile();
}





//// [ 비밀번호변경 해쉬/로드 ]
function editProfileJS(){
  const EditProfile = document.querySelector(".EditProfile");
  const EditAvatarInput = document.querySelector('.editAvatarInput');
  const EditAvatarImg = document.querySelector('.editAvatarImg');
  const EditName = document.querySelector('.edit_name');
  const UserInfoAvatar = document.querySelector(".userInfo_avatar");
  const UserInfoName = document.querySelector(".userInfo_name");

  if(window.location.hash === `#edit-profile/${UserID.innerText}`){
    EditProfile.style.display="flex";
    EditAvatarInput.value = ""; // 선택된 파일 초기화
    EditAvatarImg.src = UserInfoAvatar.src;
    EditName.value = UserInfoName.textContent;
  } else {
    EditProfile.style.display="none";
  }
}
if(UserID){
  window.addEventListener('hashchange', editProfileJS);
  window.addEventListener('load', editProfileJS);
}






//// [ 프로필수정 - 아바타 추출 ] - 해쉬 변경 시 마다 클릭 이벤트가 중복되기 때문에 밖에다가 지정해주었다!
function editAvatarChange(){
  const EditAvatarImg = document.querySelector('.editAvatarImg');
  const EditAvatarInput = document.querySelector('.editAvatarInput');
  EditAvatarImg.addEventListener('click', () => { EditAvatarInput.click();});
  EditAvatarInput.addEventListener('change', function(event) {
    const File = event.target.files[0];
    if (File) {
      const Reader = new FileReader();
      Reader.onload = function(e) {
        EditAvatarImg.src = e.target.result;
      };
      Reader.readAsDataURL(File);
    }
  });
}
if(UserID){
  editAvatarChange();
}






//// [ 프로필편집 제출클릭 ]
function editProfileSubmitJS(){
  const EditProfileSubmits = document.querySelector(".editProfile_submit");
  EditProfileSubmits.addEventListener('click', async () => {
    const EditAvatarInputs = document.querySelector('.editAvatarInput').files[0];
    const EditName = document.querySelector(".edit_name").value;
    
    function changeInfo(){
      const EditNames = document.querySelector(".edit_name");
      const EditAvatarImg = document.querySelector('.editAvatarImg');
      const MobileBottomUserAvatar = document.querySelector(".mobileBottom-userAvatar");
      const MobileBottomUserName = document.querySelector(".mobileBottom-userName");
      const MeHeaderName = document.querySelector(".me_headerName");
      const MeHeaderImg = document.querySelector(".me_headerImg");
      const UserInfoName = document.querySelector(".userInfo_name");
      const UserInfoAvatar = document.querySelector(".userInfo_avatar");
      window.location.href = `https://www.ponigress.com/#users/${UserID.innerText}`;
      UserInfoAvatar.src = EditAvatarImg.src;
      UserInfoName.textContent = EditNames.value; 
      MeHeaderImg.src = EditAvatarImg.src;
      MeHeaderName.textContent = EditNames.value;
      MobileBottomUserAvatar.src = EditAvatarImg.src;
      MobileBottomUserName.textContent = EditNames.value;
    }
  
    if(!EditName){ // 이름 없을때
      alert("이름을 입력하여주세요.");
    }else if(EditAvatarInputs && EditName) { // 아바타와 이름 둘다 업로드 시 
      changeInfo();
      const AvatarData = {
        editAvatarContentType: EditAvatarInputs ? EditAvatarInputs.type : null
      };
  
      try {
        // Pre-signed URL을 서버로부터 가져옵니다. 
        const ProfilePresignedRes = await fetch(`/users/profilePresigned`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(AvatarData)
        });
  
        if (!ProfilePresignedRes.ok) {
          throw new Error('Pre-signed URL 요청 실패');
        }
  
        const { urls, editAvatarURLS } = await ProfilePresignedRes.json();
  
        // S3에 파일을 업로드하는 함수
        async function uploadToS3(url, file) {
          const Response = await fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': file.type,  // 파일 타입 설정
            },
            body: file,  // 업로드할 파일 데이터
          });
  
          if (!Response.ok) {
            throw new Error(`파일 업로드 실패: ${file.name}`);
          }
        }
  
  
        // 아바타 리사이징 함수 (넓이만 300으로 설정)
        async function avatarResizeImg(imageFile, maxWidth) {
          const img = new Image();
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          return new Promise((resolve, reject) => {
            img.onload = () => {
              let width = img.width;
              let height = img.height;
  
              if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
              }
  
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);
  
              canvas.toBlob((blob) => {
                resolve(blob);
              }, imageFile.type);
            };
  
            img.onerror = (error) => reject(error);
            img.src = URL.createObjectURL(imageFile);
          });
        }
  
        // 아바타가 있을 경우 리사이징 후 S3 업로드
        if (urls.avatar && EditAvatarInputs) {
          const ResizedAvatarBlob = await avatarResizeImg(EditAvatarInputs, 200); // 넓이를 200으로 리사이징
          await uploadToS3(urls.avatar, ResizedAvatarBlob);
        }
  
        // S3 업로드가 완료된 후 '/api/edit-profile'로 데이터 전송
        const ProfileUploadRes = await fetch('/users/edit-profile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            avatarUrl: editAvatarURLS,
            name: EditName
          })
        });
  
        if (!ProfileUploadRes.ok) {
          throw new Error('프로필 업로드 중 오류 발생');
        }
      } catch (error) {
        console.error('업로드 중 오류 발생:', error);
      }
    }else if(EditName){ // 이름만 있을때
      // console.log("선택파일이 없음");
      // /api/edit-profile'로 데이터 전송
      changeInfo();
      const ProfileUploadRes = await fetch('/users/edit-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          avatarUrl: "null",
          name: EditName
        })
      });
      if (!ProfileUploadRes.ok) {
        throw new Error('프로필 업로드 중 오류 발생');
      }
    }
  });
}
if(UserID){
  editProfileSubmitJS();
}






//// [ 프로필편집 로그아웃 버튼 클릭 시 ]
function editProfileLogoutJs(){
  const EditProfileLogout = document.querySelector(".editProfile_logout");
  EditProfileLogout.addEventListener('click', function() {
    EditProfileLogout.innerHTML = '<div class="loader"></div>';
  });
}
if(UserID){
  editProfileLogoutJs();
}