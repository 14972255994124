// <!-- 비디오블락 JAVASCRIPT -->
const UserID = document.querySelector(".userID");



//// [ 해시/로드에 따른 비디오블락 CSS 적용 ]
function videoBlocksJS() {
  const Hash = window.location.hash;
  const VideoBlockBg = document.querySelector('.videoBlock-bg');

  if (!Hash || Hash.includes('#home')) {
    VideoBlockBg.classList.remove("videoBlock-bgClose");
    VideoBlockBg.classList.remove("videoBlock-bgB");
    VideoBlockBg.classList.remove("videoBlock-bgC");
    VideoBlockBg.classList.add("videoBlock-bgA");
  } else if(Hash.includes('#videodetail')){
    VideoBlockBg.classList.remove("videoBlock-bgClose");
    VideoBlockBg.classList.remove("videoBlock-bgA");
    VideoBlockBg.classList.remove("videoBlock-bgC");
    VideoBlockBg.classList.add("videoBlock-bgB");
  } else if(Hash.includes('#mobilesearch')){
    VideoBlockBg.classList.remove("videoBlock-bgClose");
    VideoBlockBg.classList.remove("videoBlock-bgA");
    VideoBlockBg.classList.remove("videoBlock-bgB");
    VideoBlockBg.classList.add("videoBlock-bgC");
  } else {
    VideoBlockBg.classList.remove("videoBlock-bgA");
    VideoBlockBg.classList.remove("videoBlock-bgB");
    VideoBlockBg.classList.remove("videoBlock-bgC");
    VideoBlockBg.classList.add("videoBlock-bgClose");
  }
}
if (UserID) {
  window.addEventListener('hashchange', videoBlocksJS);
  window.addEventListener('load', videoBlocksJS);
}







//// [ 비디오 썸네일 정렬 및 순서변경 ]
function updateTumbnailRandom(){
  let SavedOrder = [];
  const VideoBlock = document.querySelector('.videoBlock');
  const Blocks = VideoBlock.querySelectorAll('.Block');
  const VideoArray = Array.from(Blocks);
  let ShuffledVideos = VideoArray.sort(() => Math.random() - 0.5);
  if (SavedOrder.length && ShuffledVideos.every((Video, Index) => Video === SavedOrder[Index])) {
    const Temp = ShuffledVideos[0];
    ShuffledVideos[0] = ShuffledVideos[1];
    ShuffledVideos[1] = Temp;
  }
  const Fragment = document.createDocumentFragment();
  
  ShuffledVideos.forEach(Video => {
    Fragment.appendChild(Video);
  });
  VideoBlock.innerHTML = '';
  VideoBlock.appendChild(Fragment);
  SavedOrder = [...ShuffledVideos];
}
if(UserID){
  updateTumbnailRandom();
}







//// [ 모바일 터치로딩 보이기/숨기기 ]
function touchLoadingJS() {
  const Hash = window.location.hash;
  const TouchLoadingContainer = document.querySelector('.touchLoading-container');
  const IsMobile = window.innerWidth <= 1000; // 화면 크기 체크
  let width = window.innerWidth;

  if (IsMobile && (!Hash || Hash.includes('#home') || Hash.includes('#search'))){
    if (width <= 1000 && width > 540) {
      TouchLoadingContainer.style = "display:flex; height:52px;";
    } else if (width <= 540 && width > 340) {
      TouchLoadingContainer.style = "display:flex; height:52px;";
    } else if (width <= 340) {
      TouchLoadingContainer.style = "display:flex; height:49px;";
    }
  } else if(IsMobile && Hash.includes('#mobilesearch')){
    if (width <= 1000 && width > 540) {
      TouchLoadingContainer.style = "display:flex; height:60px;";
    } else if (width <= 540 && width > 340) {
      TouchLoadingContainer.style = "display:flex; height:60px;";
    } else if (width <= 340) {
      TouchLoadingContainer.style = "display:flex; height:57px;";
    }
  } else {
    TouchLoadingContainer.style.display = "none";
  }
}
if (UserID) {
  window.addEventListener('hashchange', touchLoadingJS);
  window.addEventListener('load', touchLoadingJS);
  window.addEventListener('resize', touchLoadingJS);
}



//// [ 원위치 돌아가기 ]
function originPosition(){
  const Hash = window.location.hash;
  const TouchLoadingContainer = document.querySelector('.touchLoading-container');
  const TouchLoading = document.querySelector('.touchLoading');
  let width = window.innerWidth;
  if(!Hash || Hash.includes('#home') || Hash.includes('#search')){
    if (width <= 1000 && width > 540) {
      TouchLoadingContainer.style.height = '52px';
    } else if (width <= 540 && width > 340) {
      TouchLoadingContainer.style.height = '52px';
    } else if (width <= 340) {
      TouchLoadingContainer.style.height = '49px';
    }
    TouchLoading.style.display="none";
    setTimeout(() => {
      TouchLoadingContainer.style.transition = 'height 0s ease';
    }, 500);
  }else if(Hash.includes('#mobilesearch')){
    if (width <= 1000 && width > 540) {
      TouchLoadingContainer.style.height = '60px';
    } else if (width <= 540 && width > 340) {
      TouchLoadingContainer.style.height = '60px';
    } else if (width <= 340) {
      TouchLoadingContainer.style.height = '57px';
    }
    TouchLoading.style.display="none";
    setTimeout(() => {
      TouchLoadingContainer.style.transition = 'height 0s ease';
    }, 500);
  }
}



//// [ 모바일 비디오블락 터치로딩 ]
const pStart = { y: 0 }; // 터치 시작 좌표
const bodyContent = document.querySelector(".bodyContent");
const TouchLoadingContainer = document.querySelector('.touchLoading-container');
const TouchLoading = document.querySelector('.touchLoading');
let isLoading = false; // 로딩 상태 추적
let swipeListenerAdded = false; // 리스너 추가 상태

// 0.4초 대기 함수 (Promise)
const waitForThreeSeconds = () => new Promise(resolve => setTimeout(resolve, 400));


const loading = () => {
  isLoading = true;
  TouchLoadingContainer.style.transition = 'height 0.5s ease';
  TouchLoadingContainer.style.height = '100px';
  setTimeout(() => {
    isLoading = false;
    originPosition();
    updateTumbnailRandom();
  }, 700);
};


// 스와이프 이벤트 처리
const handleSwipe = (e) => {
  const touchY = e.changedTouches ? e.changedTouches[0].screenY : 0;
  const changeY = touchY > pStart.y ? Math.abs(touchY - pStart.y) : 0;
  
  const Hash = window.location.hash;
  if (changeY > 100 && bodyContent.scrollTop === 0 && !isLoading) {
    if (!Hash || Hash.includes('#home') || Hash.includes('#mobilesearch')) {
      TouchLoading.style.display="block";
      loading();
    }
  }
};

// 터치 이벤트 리스너 관리
const manageSwipeListeners = (add) => {
  if (add && !swipeListenerAdded) {
    bodyContent.addEventListener("touchstart", e => pStart.y = e.touches[0].screenY);
    bodyContent.addEventListener("touchmove", handleSwipe);
    swipeListenerAdded = true;
  } else if (!add && swipeListenerAdded) {
    bodyContent.removeEventListener("touchstart", e => pStart.y = e.touches[0].screenY);
    bodyContent.removeEventListener("touchmove", handleSwipe);
    swipeListenerAdded = false;
  }
};

// 스크롤 상단에서 0.4초 대기 후 리스너 추가
const initSwipeLoading = async () => {
  // 스크롤이 상단에 있고 화면 크기가 1000px 이하인 경우에만 동작
  if (bodyContent.scrollTop === 0 && window.innerWidth <= 1000) {
    await waitForThreeSeconds(); // 0.4초 대기
    manageSwipeListeners(true);  // 리스너 추가
  } else {
    manageSwipeListeners(false); // 리스너 제거
  }
};

// 해시 변경 시 기존 리스너 삭제 및 조건에 맞게 새로 추가
const handleHashChange = () => {
  manageSwipeListeners(false); // 해시가 변경되면 기존 리스너 삭제
  initSwipeLoading();           // 새로 조건에 맞춰 리스너 추가
};
if (UserID) {
  window.addEventListener('hashchange', handleHashChange);
  window.addEventListener('load', initSwipeLoading);
  bodyContent.addEventListener('scroll', initSwipeLoading);
}