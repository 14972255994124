// <!-- 로드로딩 JAVASCRIPT -->
const UserID = document.querySelector(".userID");

//// [ 로딩 보이기/숨기기 ]
function loadLoading(){
  const LoadLoading = document.querySelector(".loadLoading");
  LoadLoading.style.display="grid";
  setTimeout(() => {
    LoadLoading.style.display="none";
  }, 1500);
}
function hashLoading(){
  const Hash = window.location.hash;
  if(Hash.includes('#search')){
    const LoadLoading = document.querySelector(".loadLoading");
    LoadLoading.style.display="grid";
    setTimeout(() => {
      LoadLoading.style.display="none";
    }, 2000);
  }
}
if(UserID){
  window.addEventListener('load', loadLoading);
  window.addEventListener('hashchange', hashLoading);
}