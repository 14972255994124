// <!-- 비디오 수정 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 비디오 수정,삭제 ]
function videoEdit(){
  const Hash = window.location.hash;
  const VideoEdit = document.querySelector(".videoEdit");
  const VideoEditTitle = document.querySelector('.videoEdit_title');
  const VideoEditDescription = document.querySelector('.videoEdit_description');
  if(Hash.includes('#videoEdit')){
    VideoEdit.style.display="flex";
    const VideoId = Hash.replace('#videoEdit/', '');
    fetch(`/api/${VideoId}/videoEditData`, { method: "POST" })
    .then(response => response.json())
    .then(data => {
      VideoEditTitle.value= data.videos.title;
      VideoEditDescription.value= data.videos.description;
    })

    // 비디오 업데이트 버튼 클릭시
    const VideoEditForms = document.querySelector('.videoEditForm');
    const VideoEditSubmitBtn = document.querySelector(".videoEdit_submit");
    function videoEditSubmits(){
      VideoEditForms.action = `/api/${VideoId}/videoEdit`;
      VideoEditSubmitBtn.submit();
    }
    VideoEditSubmitBtn.addEventListener("click" , videoEditSubmits );


    // 비디오 삭제 버튼 클릭 시
    const VideoDeleteForms = document.querySelector(".videoDeleteForm");
    const VideoDeleteSubmitBtn = document.querySelector(".videoDelete_submit");
    function videoDeleteSubmits(){
      VideoDeleteForms.action = `/api/${VideoId}/videoDelete`;
      VideoDeleteSubmitBtn.submit();
    }
    VideoDeleteSubmitBtn.addEventListener("click" , videoDeleteSubmits );

  }else{
    VideoEdit.style.display="none";
    VideoEditTitle.value = "";
    VideoEditDescription.value = "";
  }
}
if(UserID){
  window.addEventListener('hashchange', videoEdit);
  window.addEventListener('load', videoEdit);
}