// <!-- 비디오 저장/저장취소 JAVASCRIPT -->
const UserID = document.querySelector(".userID");



//// [ uS-Block 순서 텍스트 재입력 (참고:저장버튼 클릭 시만 변경, 취소는 아님) ]
function uSblockNumReset(){
  const UsVideoBlock = document.querySelector('.uSvideoBlock');
  const UsBlocks = UsVideoBlock.querySelectorAll('.uS-Block');
  UsBlocks.forEach((Block,index) => {
    const BlockNumber = Block.querySelector('.block-number');
    BlockNumber.textContent = index; // 순서 저장
  });
}



//// [ 저장취소 ]
let saveCancleId = [];
function saveCancleVideo() {
  const Hash = window.location.hash;
  if(Hash.includes('#users')){
    const UsVideoBlock = document.querySelector('.uSvideoBlock');
    saveCancleId.forEach(id => UsVideoBlock.querySelector(`.uS-Block[data-id="${id}"]`)?.remove());
    saveCancleId = [];
  }
}
if(UserID){
  window.addEventListener('hashchange', saveCancleVideo);
}




//// [ 저장 ]
function save(){
  const MainVideo = document.querySelector('.mainVideo');
  const UsVideoBlock = document.querySelector('.uSvideoBlock');
  const VideoID = MainVideo.querySelector('.block-videoId').textContent.trim();
  const HlsVideoUrl = MainVideo.querySelector('.hlsVideoUrl').textContent.trim();
  const ThumbnailUrl = MainVideo.querySelector('.thumbnailUrl').textContent.trim();
  const VideoTitle = MainVideo.querySelector('.videoTitle').textContent.trim();
  const VideoCreatorAvater = MainVideo.querySelector('.videoCreator-avater').src;
  const VideoCreatorName = MainVideo.querySelector('.videoCreator-name').textContent.trim();
  let VideoDesOrigin = "" ;
  if(MainVideo.querySelector('.videoDesOrigin')){
    VideoDesOrigin = MainVideo.querySelector('.videoDesOrigin').textContent.trim();
  }

  if (MainVideo) {
    const UsDiv = document.createElement('div');
    UsDiv.innerHTML = `
      <div class="uS-Block" data-videoUrl="${HlsVideoUrl}" data-id="${VideoID}">
        <a href="#videodetail/uS/poniVideo${VideoID}/?${HlsVideoUrl}">
          <div class="thumbnail-bg">
            ${ThumbnailUrl === "thumbnail-None" ? `
              <video class="thumbnail thumbnailVideos" id="hlsVideoId-${VideoID}" loop controlsList="nodownload"></video>
            ` : `
              <img class="thumbnail thumbnailImg" src="${ThumbnailUrl}" alt="Img">
              <video class="thumbnail thumbnailVideo" id="hlsVideoId-${VideoID}" loop controlsList="nodownload"></video>
            `}
            <div class="videoPlayer">
              <div class="videoPlayPause video-play-pause">
                <i class="fas fa-pause"></i>
              </div>
              <div class="video-screenLock">
                <i class="fas fa-unlock"></i>
              </div>
              <div class="video-fullScreen">
                <i class="bi bi-fullscreen"></i>
              </div>
              <div class="video-progress">
                <div class="progress-bar">
                  <div class="progress-filled" style="width: 0%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="block-hidden hidden"> 
            <span class="hlsVideoUrl">${HlsVideoUrl}</span>
            <span class="thumbnailUrl">${ThumbnailUrl}</span>
            <span class="block-videoId">${VideoID}</span>
          </div>
          <div class="videoInfo">
            <span class="videoTitle">${VideoTitle}</span>
            <div class="videoCreator">
              <a href="">
                <img class="videoCreator-avater" src="${VideoCreatorAvater}" alt="Creator Avatar">
                <span class="videoCreator-name">${VideoCreatorName}</span>
              </a>
              <div class="videoSetting">
                <i class="bi bi-three-dots-vertical"></i>
              </div>
              <div class="videoSettingModalBg">
                <div class="videoSettingModal">
                  <a class="settingReport" href="">신고</a>
                </div>
              </div>
            </div>
            ${VideoDesOrigin !== "" ?
              `<div class="videoDescriptionBg">
                <span class="videoDescription">${VideoDesOrigin}</span>
                <span class="videoDesOrigin hidden">${VideoDesOrigin}</span>
              </div>` : ''}
            <div class="video-saveBtnBg">
              <button class="video-saveBtn"></button>
            </div>
            <span class="saveValue hidden">yes</span>
          </div>
        </a>
        <button class="block-playBtn hidden"></button>
        <div class="block-number hidden">0</div>
      </div>
    `;

    if (UsVideoBlock) {
      UsVideoBlock.prepend(UsDiv);

      // HLS 설정
      const UsVideo = UsDiv.querySelector('video');
      const UsVideoUrlBase = 'https://video.ponigress.com/';
      const UsVideoUrl = `${UsVideoUrlBase}${HlsVideoUrl}`;
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(UsVideoUrl);
        hls.attachMedia(UsVideo);
        hls.on(Hls.Events.MANIFEST_PARSED, function() {
          hls.currentLevel = 1;
        });
        const handleCanPlays = () => {
          UsVideo.muted = true;
          UsVideo.currentTime = 0.5;
          UsVideo.removeEventListener('canplaythrough', handleCanPlays); // 이벤트 리스너 제거
        };
        UsVideo.addEventListener('canplaythrough', handleCanPlays);
      } else if (UsVideo.canPlayType('application/vnd.apple.mpegurl')) {
        UsVideo.src = UsVideoUrl;
      }
      uSblockNumReset();
    }
  }
}










//// [ 저장버튼 클릭 시 fetch 로 데이터 저장/저장취소 ]
async function saveBtnClick() {
  const Hash = window.location.hash;
  const HlsVideoUrl = Hash.split('?')[1];
  const VideoId = Hash.split('poniVideo')[1].split('/?')[0];

  const updateSaveBtn = (block, isSaved) => {
    const saveBtn = block.querySelector('.video-saveBtn');
    const saveValue = block.querySelector('.saveValue');
    if (isSaved) {
      saveBtn.innerHTML = '<i class="bi bi-bookmark-fill"></i>';
      saveBtn.style = "background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
      saveValue.textContent = "yes";
    } else {
      saveBtn.textContent = "저장";
      saveBtn.style = "background-color:blue; color:white;";
      saveValue.textContent = "no";
    }
  };

  // UI 즉시 업데이트
  const isSaved = document.querySelector('.saveValue').textContent === "yes";
  updateSaveBtn(document.querySelector('.mainVideo'), !isSaved);

  // 추가된 블록들에 대해서도 즉시 업데이트
  [`.Block[data-videoUrl="${HlsVideoUrl}"]`, `.uS-Block[data-videoUrl="${HlsVideoUrl}"]`].forEach(selector => {
    const block = document.querySelector(selector);
    if (block) updateSaveBtn(block, !isSaved);
  });

  // 서버에 비동기 요청
  if (isSaved) {
    fetch(`/api/${VideoId}/save/cancle`, { method: "POST" });
  } else {
    fetch(`/api/${VideoId}/save`, { method: "POST" });
  }

  // 저장함수 실행
  if(isSaved){
    saveCancleId.push(VideoId);
  }else{
    saveCancleId = saveCancleId.filter(id => id !== VideoId);
    if(!Hash.includes('uS/poniVideo')){
      const UsVideoBlock = document.querySelector(`.uSvideoBlock`);
      const UsBlock = UsVideoBlock.querySelector(`.uS-Block[data-videoUrl="${HlsVideoUrl}"]`);
      if(!UsBlock){
        save();
      }
    }else{
      // 아쉬운점: uS 일때 저장을 하면 원래 그 자리로 돌아감. 맨 앞에 위치시키면 너무 좋을것 같다.
    }
  }
}






//// [ saveValue 값에 따른 저장버튼 업데이트 ]
function updateSaveBtn() {
  const Hash = window.location.hash;
  const MainVideo = document.querySelector('.mainVideo');
  const Video = MainVideo.querySelector('video');
  const SaveValue = MainVideo.querySelector('.saveValue');

  if (Video && SaveValue && Hash.includes('#videodetail')) {
    const VideoSaveBtn = MainVideo.querySelector('.video-saveBtn');
    if (SaveValue.textContent === "no") {
      VideoSaveBtn.textContent = "저장";
      VideoSaveBtn.style = "background-color:blue; color:white;";
    } else {
      VideoSaveBtn.innerHTML = '<i class="bi bi-bookmark-fill"></i>';
      VideoSaveBtn.style = "background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
    }
    VideoSaveBtn.removeEventListener('click', saveBtnClick);
    VideoSaveBtn.addEventListener('click', saveBtnClick);
  }
}
function loadSave(){
  // MutationObserver 적용
  const MainVideo = document.querySelector('.mainVideo');
  const observer = new MutationObserver(() => {
    const Video = MainVideo.querySelector('video');
    if (Video) {
      observer.disconnect(); // video 요소가 감지되면 observer 종료
      updateSaveBtn();
    }
  });
  observer.observe(MainVideo, { childList: true, subtree: true });
}
if (UserID) {
  window.addEventListener('hashchange', updateSaveBtn);
  window.addEventListener('load', loadSave);
}